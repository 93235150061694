import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { API_URL, FORCE_SECURED_ENDPOINT } from './terminal.constant';
import { b64DecodeUnicode, b64EncodeUnicode } from '@zerops/zef/entities';

@Injectable({ providedIn: 'root' })
export class TerminalApi {
  #http = inject(HttpClient);
  #apiUrl = inject(API_URL);
  #forceSecuredEndpoint = inject(FORCE_SECURED_ENDPOINT);

  auth$(serviceId: string, containerId?: string): Observable<{ accessToken: string; host: string }> {
    return this.#http.post<{
      accessToken: string;
      listUrl: string;
    }>(
      `/api/service-stack/${serviceId}/file-browsing-access`,
      { containerId }
    ).pipe(
      map(({ accessToken, listUrl }) => ({
        accessToken,
        host: this.#parseHost(listUrl)
      }))
    );
  }

  createWebSocketConnection(token: string, containerId: string, host: string): WebSocketSubject<any> {
    return webSocket({
      url: this.#getConnectEndpoint(token, containerId, host),
      deserializer: ({ data }) => ({
        type: parseInt(data[0], 10),
        message: data.slice(1)
          ? b64DecodeUnicode(data.slice(1))
          : undefined
      }),
      serializer: (data) => data.type+b64EncodeUnicode(data.message)
    });
  }

  #getConnectEndpoint(token: string, containerId: string, host: string): string {
    let protocol = window.location.protocol === 'https:' ? 'wss' : 'ws';
    if (this.#forceSecuredEndpoint) {
      protocol = 'wss';
    }

    const containerQuery = containerId ? `&containerId=${containerId}` : '';
    return `${protocol}://${host}${this.#apiUrl ? `/${this.#apiUrl}` : '/api/rest/shell/stream'}?accessToken=${token}${containerQuery}`;
  }

  #parseHost(listUrl: string): string {
    const parts: string[] = listUrl.split(' ');
    if (parts.length > 1) {
      const fullUrl = parts[1];
      const domain = fullUrl.split('/')[0];
      return domain;
    } else {
      console.warn("URL format is not as expected");
      return '';
    }
  }
}
