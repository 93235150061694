export enum RecipeCategory {
  Runtimes = 'runtimes',
  Backend = 'backend',
  Frontend = 'frontend',
  ProjectUtility = 'project-utility',
  ServiceUtility = 'service-utility',
  PlatformBots = 'platform-bots',
  Other = 'other'
}

export interface Recipe {
  id: string;
  slug?: string;
  recipeId?: string;
  enableOnboarding?: boolean;
  tag?: string;
  logo?: string;
  icon?: string;
  category?: RecipeCategory;
  importYaml: string;
  desc?: string;
  disabled?: boolean;
  listed: boolean;
  title?: string;
  content?: string;
  repository?: {
    name?: string;
    repository: string;
  }[];
}
