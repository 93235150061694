import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  HostBinding,
  ElementRef
} from '@angular/core';
import { UserDataContentParser } from '@zerops/zerops/core/user-data-base';
import { SatPopover } from '@zerops/zef/popover';
import { UserData, UserDataTypes } from '@zerops/models/user-data';
import { ServiceStackStatuses } from '@zerops/models/service-stack';
import { UserDataItemTranslations } from './user-data-item.translations';

@Component({
  selector: 'z-user-data-item',
  templateUrl: './user-data-item.component.html',
  styleUrls: [ './user-data-item.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserDataItemComponent {

  userDataTypes = UserDataTypes;

  @Input()
  set data(v) {
    this._data = v;

    this.formattedContent = this._contentParser.parse(this._data.content);
  }
  get data() {
    return this._data;
  }

  @Input()
  matches: any[];

  @Input()
  serviceStackStatus: ServiceStackStatuses;

  @Input()
  moreAnchor: SatPopover;

  @Input()
  moreActionLoading: boolean;

  @Input()
  translations: UserDataItemTranslations;

  @Output()
  moreClicked = new EventEmitter<void>();

  formattedContent: any;

  @HostBinding('class.is-more-action-loading')
  get classIsLoading() {
    return this.moreActionLoading;
  }

  serviceStackStatuses = ServiceStackStatuses;
  private _data: Partial<UserData>;

  constructor(
    public elementRef: ElementRef,
    private _contentParser: UserDataContentParser
  ) { }
}
