<div
  [class.has-filters]="showFilters()"
  class="__host">
  @if (showFilters()) {
    <div class="__filters">

      <!-- container filter -->
       @if (containers()) {
        <div class="__filter-item">

          <button
            (click)="containerPopRef.open()"
            [satPopoverAnchor]="containerPopRef"
            mat-flat-button
            class="c-button--smaller  __filter-button">
            <mat-icon class="fx:align-middle  __filter-item_icon" fontSet="material-icons-outlined">dns</mat-icon>
            <span>{{ containerMap()?.[containerId()]?.hostname }}</span>
            <mat-icon>keyboard_arrow_down</mat-icon>
          </button>

          <sat-popover
            #containerPopRef
            [autoFocus]="false"
            [restoreFocus]="false"
            hasBackdrop
            (backdropClicked)="containerPopRef.close()"
            [verticalAlign]="'below'"
            [horizontalAlign]="'center'">
            <mat-card class="__filter-pop-card  z-manual-elevation  c-soft-elevation">
              <mat-action-list class="__filter-action-list" dense>
                @for (item of containers(); track $index) {
                  <button
                    class="__filter-option"
                    (click)="filterChange.emit({ containerId: item.id }); containerPopRef.close();"
                    [class.is-active]="containerId() === item.id"
                    mat-list-item
                    type="button">
                    <zui-action-list-item
                      [mode]="'dense'"
                      [label]="item.hostname"
                    />
                  </button>
                }
              </mat-action-list>

            </mat-card>
          </sat-popover>

        </div>
      }

      <div class="__filter-item">
        @if (connectionStatus()) {
          <div>
            <span class="__status  __status--active"></span>
            <span class="__status-text">Connected</span>
          </div>
        } @else {
          <div>
            <span class="__status  __status--inactive"></span>
            <span class="__status-text">
              {{ connectingStatus() === true ? 'Connecting' : 'Disconnected' }}
            </span>

            @if (!connectingStatus()) {
              <button
                (click)="reconnect()"
                class="__reconnect-button  c-button--smaller"
                mat-button>
                <mat-icon>replay</mat-icon>
                Reconnect
              </button>
            }
          </div>
        }
      </div>

    </div>
  }

  <ng-terminal />
</div>
