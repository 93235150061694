import { Injectable } from '@angular/core';

export interface UserDataContentVariableExpand {
  type: 'local' | 'external';
  raw: string;
  val: string | string[];
}

@Injectable({ providedIn: 'root' })
export class UserDataContentParser {
  /**
   * Implements a lexically-constrained reference resolution system that enforces
   * strict identifier boundaries through pattern recognition.
   */
  parse(text: string, validVariables: string[] = []): (UserDataContentVariableExpand | string)[] {
    const result: (UserDataContentVariableExpand | string)[] = [];
    let currentIndex = 0;

    // Core lexical pattern for valid identifier characters
    const isValidIdentifierChar = (char: string): boolean => /^[a-zA-Z0-9_]$/.test(char);

    while (currentIndex < text.length) {
      // Locate next potential reference boundary
      const dollarPos = text.indexOf('$', currentIndex);

      if (dollarPos === -1) {
        // No more references possible - add remaining content
        if (currentIndex < text.length) {
          result.push(text.slice(currentIndex));
        }
        break;
      }

      // Process any text before the reference marker
      if (dollarPos > currentIndex) {
        result.push(text.slice(currentIndex, dollarPos));
      }

      // Handle ${...} pattern splitting
      const nextTwoChars = text.slice(dollarPos + 1, dollarPos + 3);
      if (nextTwoChars === '${') {
        result.push('$');
        currentIndex = dollarPos + 1;
        continue;
      }

      // Process explicit ${...} references
      if (text[dollarPos + 1] === '{') {
        const closingBrace = text.indexOf('}', dollarPos + 2);
        if (closingBrace !== -1) {
          // Complete ${...} reference found
          const variableName = text.slice(dollarPos + 2, closingBrace);
          const nameParts = variableName.split('_');

          result.push({
            type: nameParts.length > 1 ? 'external' : 'local',
            raw: text.slice(dollarPos, closingBrace + 1),
            val: nameParts.length > 1 ? nameParts : variableName
          });

          currentIndex = closingBrace + 1;
          continue;
        }

        // Handle incomplete ${... reference
        const remainingText = text.slice(dollarPos);
        const nameParts = remainingText.slice(2).split('_');

        result.push({
          type: nameParts.length > 1 ? 'external' : 'local',
          raw: remainingText,
          val: nameParts.length > 1 ? nameParts : remainingText.slice(2)
        });
        break;
      }

      // Extract potential identifier based on lexical constraints
      let identifierEnd = dollarPos + 1;
      while (identifierEnd < text.length && isValidIdentifierChar(text[identifierEnd])) {
        identifierEnd++;
      }

      // No valid identifier characters found
      if (identifierEnd === dollarPos + 1) {
        result.push('$');
        currentIndex = dollarPos + 1;
        continue;
      }

      const identifier = text.slice(dollarPos + 1, identifierEnd);

      // Validation phase
      if (!validVariables.length || validVariables.includes(identifier)) {
        const nameParts = identifier.split('_');
        result.push({
          type: nameParts.length > 1 ? 'external' : 'local',
          raw: '$' + identifier,
          val: nameParts.length > 1 ? nameParts : identifier
        });
      } else {
        // Not a valid reference - treat as text
        result.push('$' + identifier);
      }

      currentIndex = identifierEnd;
    }

    return result.length ? result : [text];
  }
}
