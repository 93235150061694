import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { ZefReactiveComponent } from '@zerops/zef/core';
import { selectZefDialogState, zefDialogClose } from '@zerops/zef/dialog';
import { AppState } from '@zerops/zerops/app';
import { ZefSnackService } from '@zerops/zef/snack';
import { projectImport } from '@zerops/zerops/core/project-base';
import { serviceStackImport } from '@zerops/zerops/core/service-stack-base';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, map, tap, withLatestFrom } from 'rxjs/operators';
import { FEATURE_NAME } from './import-export-dialog.constant';
import { ImportExportDialogForm } from './import-export-dialog.form';
import { ImportExportDialogModes } from './import-export-dialog.model';
import { ThemeService } from '@zerops/zef/theme';
import { RECIPES, RecipeCategory, getRecipeByCategory } from '@zerops/models/recipes';

export enum DialogEntities {
  Project = 'project',
  ServiceStack = 'service-stack'
}

@Component({
  selector: 'z-import-export-dialog',
  templateUrl: './import-export-dialog.container.html',
  styleUrls: [ './import-export-dialog.container.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImportExportDialogContainer extends ZefReactiveComponent {

  // # Event Streams
  onClose$ = new Subject<void>();
  onProjectImport$ = new Subject<void>();
  onServiceStackImport$ = new Subject<void>();
  onSetEditorValue$ = new Subject<string>();

  // # Forms
  formState$ = this._importExportDialogForm.state$;

  // # Data
  // -- sync
  modes = ImportExportDialogModes;
  projectImportKey = projectImport.type;
  serviceStackImportKey = serviceStackImport.type;
  dialogEntities = DialogEntities;
  recipesLink = window.location.hostname + '/dashboard/recipes';
  recipeList = RECIPES.filter((d) => d.listed && !d.disabled);
  recipesByCategory = getRecipeByCategory(this.recipeList);
  recipeCategories = RecipeCategory;

  // -- async
  open$ = this._store.pipe(
    select(selectZefDialogState(FEATURE_NAME)),
    map((data) => data.state)
  );
  yaml$ = this._store.pipe(
    select(selectZefDialogState(FEATURE_NAME)),
    map(({ meta }) => meta?.yaml as string),
    tap(() => this._cdRef.markForCheck()),
    filter((d) => !!d)
  );
  mode$ = this._store.pipe(
    select(selectZefDialogState(FEATURE_NAME)),
    map(({ meta }) => meta?.mode as ImportExportDialogModes),
    filter((d) => !!d)
  );
  entity$ = this._store.pipe(
    select(selectZefDialogState(FEATURE_NAME)),
    map(({ meta }) => meta?.entity as DialogEntities),
    filter((d) => !!d)
  );
  projectId$ = this._store.pipe(
    select(selectZefDialogState(FEATURE_NAME)),
    map(({ meta }) => meta?.projectId as string)
  );

  // # State resolver
  state = this.$connect({
    open: this.open$,
    formState: this.formState$,
    mode: this.mode$,
    yaml: this.yaml$,
    entity: this.entity$,
    isDarkTheme: this._themeService.isDarkMode$
  });

  // # Action Streams
  private _closeAction$ = this.onClose$.pipe(
    map(() => zefDialogClose({ key: FEATURE_NAME }))
  );
  private _setEditorValueAction$ = this.onSetEditorValue$.pipe(
    map((d) => this._importExportDialogForm.setValue('yaml', d))
  );
  private _projectImportAction$ = this.onProjectImport$.pipe(
    withLatestFrom(this.formState$),
    map(([ _, formState ]) => projectImport({ yaml: formState.controls.yaml.value }, { type: 'pop' }))
  );
  private _serviceStackImportAction$ = this.onServiceStackImport$.pipe(
    withLatestFrom(
      this.formState$,
      this.projectId$
    ),
    map(([ _, formState, projectId ]) => serviceStackImport({ data: { yaml: formState.controls.yaml.value, projectId }}))
  );

  constructor(
    private _cdRef: ChangeDetectorRef,
    private _store: Store<AppState>,
    private _clipboardService: Clipboard,
    private _snack: ZefSnackService,
    private _importExportDialogForm: ImportExportDialogForm,
    private _themeService: ThemeService
  ) {
    super();

    this.$dispatchActions([
      this._closeAction$,
      this._projectImportAction$,
      this._serviceStackImportAction$,
      this._setEditorValueAction$
    ]);
  }

  copy(yaml: string) {
    this._clipboardService.copy(yaml);
    this._snack.info$({ translation: 'general.copySuccess' });
  }

}
