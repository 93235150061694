<mat-card class="__card  z-manual-elevation  c-soft-elevation">

  <h4 class="__title">
    @if (data?.length > 1 || data?.[0]?.originalError.error.error?.meta?.length > 1) {
      Oof, couple things went wrong went wrong …
    } @else if (data?.length) {
      {{ data[0].originalError.error.error?.meta?.[0]?.error || data?.message }}
    }
  </h4>

  <div class="__content">
    @for (item of data; track $index) {
      @if (item.originalError.error.error?.meta?.length) {

        @if (item.originalError.error.error?.meta?.length > 1) {
          {{ item.originalError.error.error?.meta?.[0]?.error }}
        }

        @for (meta of item.originalError.error.error?.meta; track $index) {
          @if (!!meta.metadata) {
            <pre class="__meta-info"><code>{{ meta.metadata | json }}</code></pre>
          }
        }

      }
    }
  </div>

  <button
    mat-button
    class="__button  c-button--full"
    (click)="instance.close()">
    <mat-icon class="__button_icon">report</mat-icon> Okay, close.
  </button>

</mat-card>
