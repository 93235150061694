import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import {
  map,
  // switchMap,
  filter,
  tap,
  withLatestFrom,
  take,
  mergeMap,
  // takeUntil,
  delay
} from 'rxjs/operators';
import { zefGo } from '@zerops/zef/ngrx-router';
import {
  zefLogout,
  zefCheckStoredTokenSuccess,
  zefSetToken,
  zefSelectAuthAccessToken,
  zefLoginSuccess,
  zefCheckStoredTokenFail,
  zefRefreshTokenFail
} from '@zerops/zef/auth';
// TODO: fix import ??
import {
  zefWebsocketClosed,
  zefWebsocketTerminate
} from '@zerops/zef/websocket/websocket.action';
import { zefWebsocketAuth, ZefWebsocketService } from '@zerops/zef/websocket';
import { zefResetState } from '@zerops/zef/core';
import { getRecipeById } from '@zerops/models/recipes';
import {
  loadStoredUserDataSuccess,
  loadUser,
  clearUserData,
  loadStoredUserData,
  UserEntity,
  storeUserDataSuccess,
  setUserId
} from '@zerops/zerops/core/user-base';
import { loadSettings } from '@zerops/zerops/core/settings-base';
import { loadProjectTags, projectImport, queueProjectImport } from '@zerops/zerops/core/project-base';
import { AppState } from './app.model';
import { RecipesService, requestRecipeAdd } from '@zerops/zui/recipes';
import { zefGithubLoginSuccess, zefGithubSignupSuccess } from '@zerops/zef/github';
import { zefGitlabLoginSuccess, zefGitlabSignupSuccess } from '@zerops/zef/gitlab';
// import { interval } from 'rxjs';
import { Intercom } from '@zerops/zef/intercom';

@Injectable()
export class AppEffect {

  private _onAnyAuthSuccess$ = this._actions$.pipe(ofType(
    zefSetToken,
    loadStoredUserDataSuccess
  ));

  private _onAnyGithubGitlabAuthSuccess$ = this._actions$.pipe(
    ofType(
      zefGithubLoginSuccess,
      zefGithubSignupSuccess,
      zefGitlabLoginSuccess,
      zefGitlabSignupSuccess
    )
  );

  private _onZefSetToken$ = this._actions$.pipe(ofType(zefSetToken));

  private _onZefCheckStoredTokenSuccess$ = this._actions$.pipe(ofType(
    zefCheckStoredTokenSuccess
  ));

  private _onAnyAuthClear$ = this._actions$.pipe(ofType(zefLogout, zefCheckStoredTokenFail, zefRefreshTokenFail));

  private _onCheckStoredTokenSuccessSetUserId$ = createEffect(() => this._onZefCheckStoredTokenSuccess$.pipe(
    map(({ userId }) => setUserId(userId)),
  ));

  private _onAnyAuthSuccessGenerateReceiverId$ = createEffect(() => this._onZefSetToken$.pipe(
    tap(() => this._wService.generateReceiverId())
  ), { dispatch: false });

  private _onCheckStoredTokenSuccess$ = createEffect(() => this._onZefCheckStoredTokenSuccess$.pipe(
    mergeMap(() => [
      loadUser(),
      loadStoredUserData()
    ])
  ));

  private _onLogoutRedirect$ = createEffect(() => this._onAnyAuthClear$.pipe(
    map(() => zefGo([ '/login' ]))
  ));

  private _onLogoutResetState$ = createEffect(() => this._onAnyAuthClear$.pipe(
    map(() => zefResetState())
  ));

  private _onLogoutWebsocketTerminate$ = createEffect(() => this._onAnyAuthClear$.pipe(
    map(() => zefWebsocketTerminate())
  ));

  private _onLogoutCleanupIntercom$ = createEffect(() => this._onAnyAuthClear$.pipe(
    tap(() => this._intercom.shutdown())
  ), { dispatch: false });

  private _onAnyAuthSuccessLoadSettings$ = createEffect(() => this._onAnyAuthSuccess$.pipe(
    map(() => loadSettings())
  ));

  private _onAnyAuthSuccessLoadProjectTags$ = createEffect(() => this._userEntity.activeClientId$.pipe(
    map((clientId) => loadProjectTags(clientId))
  ));

  private _onSetTokenLoadUser$ = createEffect(() => this._onZefSetToken$.pipe(
    map(({ meta }) => loadUser({ meta }))
  ));

  private _onLogoutClearUser$ = createEffect(() => this._onAnyAuthClear$.pipe(
    map(() => clearUserData())
  ));

  // websockets auth triggers
  private _onZefSetTokenAuthWebsockets$ = createEffect(() => this._onZefSetToken$.pipe(
    map(({ data: { accessToken }}) => zefWebsocketAuth({
      token: accessToken,
      receiverId: this._wService.getReceiverId()
    }, { type: 'noop' }))
  ));

  private _onZefCheckStoredSuccessAuthWebsockets$ = createEffect(() => this._onZefCheckStoredTokenSuccess$.pipe(
    // needed to allow forFeature websocket effect to register
    delay(0),
    map(({ accessToken }) => zefWebsocketAuth({ token: accessToken, receiverId: this._wService.getReceiverId() }, { type: 'noop' }))
  ));

  private _onZefWsClosed$ = createEffect(() => this._actions$.pipe(
    ofType(zefWebsocketClosed),
    withLatestFrom(this._store.pipe(select(zefSelectAuthAccessToken))),
    filter(([ _, token ]) => !!token),
    map(([ _, token ]) => zefWebsocketAuth({ token, receiverId: this._wService.getReceiverId() }, { type: 'noop' }))
  ));

  private _onRequestRecipeAdd$ = createEffect(() => this._actions$.pipe(
    ofType(requestRecipeAdd),
    map(({ yaml, id }) => projectImport({ data: { yaml }, meta: { tag: id } }))
  ));

  // DEPRECATED: strapi recipes
  // private _onQueueProjectImport$ = createEffect(() => this._actions$.pipe(
  //   ofType(queueProjectImport),
  //   mergeMap((action) => this._userEntity.activeClientId$.pipe(
  //     filter((d) => !!d),
  //     take(1),
  //     switchMap(() => this._recipesRervice
  //       .selectById$(action.nonHaRecipeId || action.haRecipeId)
  //       .pipe(
  //         filter((d) => !!d),
  //         take(1),
  //         map((data) => projectImport(action.nonHaRecipeId ? data.attributes.config_nonha : data.attributes.config_ha))
  //       )
  //     )
  //   ))
  // ));

  private _onQueueProjectImport$ = createEffect(() => this._actions$.pipe(
    ofType(queueProjectImport),
    mergeMap((action) => this._userEntity.activeClientId$.pipe(
      filter((d) => !!d),
      take(1),
      map(() => {
        const recipe = getRecipeById(action.nonHaRecipeId);
        if (!recipe) { return undefined; }
        return projectImport({
          yaml: recipe.importYaml,
          recipeSource: recipe.enableOnboarding ? recipe.recipeId : undefined
        });
      }),
      filter((d) => !!d)
    )
  )));


  private _onHandleStoreUserDataSuccess$ = createEffect(() => this._actions$.pipe(
    ofType(storeUserDataSuccess),
    filter(({ refresh }) => refresh === true),
    map(() => zefGo([ '/dashboard' ])),
    tap(() => setTimeout(() => window.location.reload()))
  ));

  // TEMP: periodically kill websocket to make sure it works 🤷‍♂️
  // private _websocketKiller$ = createEffect(() => this._userEntity.activeClientId$.pipe(
  //   switchMap(() => interval(55000).pipe(
  //     map(() => zefWebsocketClosed()),
  //     takeUntil(this._onAnyAuthClear$.pipe(take(1)))
  //   ))
  // ));

  private _onLoginSuccessImportRecipe$ = createEffect(() => this._actions$.pipe(
    ofType(zefLoginSuccess),
    map((action) => action.originalAction?.meta?.recipe),
    filter((d) => !!d),
    map((action) => queueProjectImport({ nonHaRecipeId: action.originalAction.meta.recipe }))
  ))

  constructor(
    private _actions$: Actions<any>,
    private _store: Store<AppState>,
    private _userEntity: UserEntity,
    private _wService: ZefWebsocketService,
    private _recipesRervice: RecipesService,
    private _intercom: Intercom
  ) { }
}
