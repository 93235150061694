<h4 class="__title  fx:pb-0-5">Stop build phase</h4>

@for (item of options; track $index) {
  <button
    type="button"
    [disabled]="state.buildDebugLoading"
    class="__mode-button"
    [class.is-active]="(
      item.mode === debugModes.NONE
      && state.pipelineDebugUserDataMap?.[debugPhasesKeys.DEBUG_BUILD] === undefined
    )
    || item.mode === state.pipelineDebugUserDataMap?.[debugPhasesKeys.DEBUG_BUILD]"
    mat-button
    (click)="onSetDebug$.next({
      phase: debugPhases.DEBUG_BUILD,
      mode: item.mode
    })">
    {{ item.label }}
  </button>
}

<h4 class="__title  fx:pt-1-5  fx:pb-0-5">Stop runtime prepare phase</h4>

@for (item of options; track $index) {
  <button
    type="button"
    [disabled]="state.runtimprepareDebugLoading"
    class="__mode-button"
    [class.is-active]="(
      item.mode === debugModes.NONE
      && state.pipelineDebugUserDataMap?.[debugPhasesKeys.DEBUG_RUNTIME_PREPARE] === undefined
    )
    || item.mode === state.pipelineDebugUserDataMap?.[debugPhasesKeys.DEBUG_RUNTIME_PREPARE]"
    mat-button
    (click)="onSetDebug$.next({
      phase: debugPhases.DEBUG_RUNTIME_PREPARE,
      mode: item.mode
    })">
    {{ item.label }}
  </button>
}
