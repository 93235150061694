import { environment } from '@zerops/zerops/env';
import { ServiceStackTypes } from '@zerops/models/service-stack';

export function getServiceDocsLink(serviceStackTypeId: ServiceStackTypes) {

  const docsUrl = environment.documentationUrl;
  const runTimesUrl = 'services/runtimes/';
  const databasesUrl = 'services/databases/';
  const searchEnginesUrl = 'services/search-engines/';
  const messageBrokersUrl = 'services/message-brokers/';
  const storagesUrl = 'services/storage/';
  const nginxUrl = 'services/static-servers/';

  const docsServiceMap = {
    [ServiceStackTypes.Golang]: `${docsUrl}${runTimesUrl}golang.html`,
    [ServiceStackTypes.NodeJs]: `${docsUrl}${runTimesUrl}nodejs.html`,
    [ServiceStackTypes.Bun]: `${docsUrl}${runTimesUrl}bun.html`,
    [ServiceStackTypes.Ubuntu]: `${docsUrl}${runTimesUrl}ubuntu.html`,
    [ServiceStackTypes.PhpApache]: `${docsUrl}${runTimesUrl}php.html`,
    [ServiceStackTypes.PhpNginx]: `${docsUrl}${runTimesUrl}php.html`,
    [ServiceStackTypes.MariaDb]: `${docsUrl}${databasesUrl}mariadb.html`,
    [ServiceStackTypes.Postgres]: `${docsUrl}${databasesUrl}postgresql.html`,
    [ServiceStackTypes.Redis]: `${docsUrl}${databasesUrl}keydb.html`,
    [ServiceStackTypes.Elasticsearch]: `${docsUrl}${searchEnginesUrl}elasticsearch.html`,
    [ServiceStackTypes.Meilisearch]: `${docsUrl}${searchEnginesUrl}meilisearch.html`,
    [ServiceStackTypes.Typesense]: `${docsUrl}${searchEnginesUrl}typesense.html`,
    [ServiceStackTypes.RabbitMq]: `${docsUrl}${messageBrokersUrl}rabbitmq.html`,
    [ServiceStackTypes.SharedStorage]: `${docsUrl}${storagesUrl}shared.html`,
    [ServiceStackTypes.ObjectStorage]: `${docsUrl}${storagesUrl}s3.html`,
    [ServiceStackTypes.Nginx]: `${docsUrl}${nginxUrl}nginx.html`,
    [ServiceStackTypes.DotNet]: `${docsUrl}${runTimesUrl}dotnet.html`,
    [ServiceStackTypes.Rust]: `${docsUrl}${runTimesUrl}rust.html`,
    [ServiceStackTypes.Python]: `${docsUrl}${runTimesUrl}python.html`
  };

  return docsServiceMap[serviceStackTypeId];
}
