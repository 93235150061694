/* eslint-disable @typescript-eslint/no-unused-vars */
import { Component, ViewChild } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { firstAvailable, log, ZefReactiveComponent } from '@zerops/zef/core';
import { AppState } from '@zerops/zerops/app';
import { selectZefDialogState } from '@zerops/zef/dialog';
import { zefDialogClose } from '@zerops/zef/dialog';
import { BehaviorSubject, combineLatest, of, Subject } from 'rxjs';
import { StripeCardComponent, StripeService } from 'ngx-stripe';
import {
  StripeCardElementOptions,
  StripeElementChangeEvent,
  StripeElementsOptions
} from '@stripe/stripe-js';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  switchMap,
  take,
  takeUntil,
  withLatestFrom
} from 'rxjs/operators';
import isEmpty from 'lodash-es/isEmpty';
import { selectCountries, selectCountryMap } from '@zerops/zerops/core/settings-base';
import {
  confirmPayment,
  confirmPaymentFail,
  confirmPaymentSuccess,
  paymentRequest,
  paymentRequestFail,
  paymentSources,
  removePaymentSource,
  selectPaymentClientSecret,
  selectPaymentSources
} from '@zerops/zerops/core/billing-base';
import {
  billingInfo,
  selectBillingInfo,
  updateBillingInfo,
  updateBillingInfoFail,
  updateBillingInfoSuccess
} from '@zerops/zerops/core/client-base';
import { selectZefProgressesByType } from '@zerops/zef/progress';
import { loadCountryList } from '@zerops/zerops/core/settings-base';
import { BillingInfoFormComponent } from '@zerops/zerops/common/billing-info-form';
import { formValueOnValid } from '@zerops/zef/forms';
import { ClientBaseBillingInfo } from '@zerops/models/client';
import { PaymentSource, PaymentStatuses } from '@zerops/models/billing';
import { TopUpAmountForm, TopUpAmountFormComponent } from './modules';
import { FEATURE_NAME } from './top-up-dialog.constant';
import { TopUpBillingInfoForm } from './modules/billing-info-form';
import { TopUpPromoCodeForm } from './modules/top-up-promo-code-form';
import { PaymentEntity } from '@zerops/zerops/core/payment-base';
import { UserEntity } from '@zerops/zerops/core/user-base';
import trim from 'lodash-es/trim';

@Component({
  selector: 'z-top-up-dialog',
  templateUrl: './top-up-dialog.container.html',
  styleUrls: [ './top-up-dialog.container.scss' ]
})
export class TopUpDialogContainer extends ZefReactiveComponent {

  // # Event Streams
  onClose$ = new Subject<void>();
  onTopUp$ = new Subject<void>();

  // # Forms
  topUpAmountFormState$ = this._topUpAmountForm.state$;
  topUpPromoCodeFormState$ = this._topUpPromoCodeForm.state$;
  billingInfoFormState$ = this._billingInfoForm.state$;
  onRemovePaymentSource$ = new Subject<string>();

  // # Data
  // -- sync
  key = FEATURE_NAME;
  confirmPaymentKey = confirmPayment.type;
  paymentRequestKey = paymentRequest.type;
  updateBillingInfoKey = updateBillingInfo.type;
  cardOptions: StripeCardElementOptions = {
    hidePostalCode: true,
    style: {
      base: {
        iconColor: '#0077CC',
        color: '#1A1A1A',
        fontFamily: '"Roboto", sans-serif',
        fontSize: '16px',
        '::placeholder': {
          color: '#949494'
        }
      }
    }
  };
  elementsOptions: StripeElementsOptions = {
    locale: 'en'
  };

  // -- angular
  @ViewChild(StripeCardComponent, { static: false })
  card: StripeCardComponent;

  @ViewChild(TopUpAmountFormComponent, { static: false })
  topUpFormRef: TopUpAmountFormComponent;

  @ViewChild(BillingInfoFormComponent, { static: false })
  billingFormRef: BillingInfoFormComponent;

  // -- async
  dialogState$ = this._store.pipe(
    select(selectZefDialogState(FEATURE_NAME)),
    map((data) => data.state)
  );
  open$ = this.dialogState$.pipe(filter((d) => d === true));
  close$ = this.dialogState$.pipe(filter((d) => d === false));
  activeClientUser$ = this._userEntity.activeClientUser$;
  stripeCardValid$ = new BehaviorSubject<boolean>(false);
  amountFormValid$ = this._topUpAmountForm.valid$;
  countryList$ = this._store.pipe(select(selectCountries));
  countryMap$ = this._store.pipe(select(selectCountryMap));
  countryMapFilled$ = this.countryMap$.pipe(
    filter((d) => !isEmpty(d)),
    take(1)
  );
  paymentSources$ = this.dialogState$.pipe(
    switchMap((d) => {
      if (d) {
        return this._store.pipe(
          takeUntil(this.close$),
          select(selectPaymentSources),
          distinctUntilChanged((a, b) => a?.length === b?.length)
        );
      }
      return of([]);
    })
  );
  billingInfo$ = this.dialogState$.pipe(
    switchMap(() => this._store.pipe(
      takeUntil(this.close$),
      select(selectBillingInfo),
      filter((d) => !!d)
    ))
  );
  currentBillingInfo$ = this.billingInfo$.pipe(map(({ current }) => current));
  isLoadingEssentialData$ = this.dialogState$.pipe(
    switchMap(() => this._store.pipe(
      takeUntil(this.close$),
      select(selectZefProgressesByType([
        billingInfo.type,
        paymentSources.type,
        loadCountryList.type
      ])),
      map((d) => !!d?.length),
      distinctUntilChanged()
    ))
  );
  currentPaymentState$ = this.dialogState$.pipe(
    switchMap(() => combineLatest([
      this.isLoadingEssentialData$,
      this.currentBillingInfo$,
      this.paymentSources$.pipe(filter((d) => d !== undefined))
    ]).pipe(
      takeUntil(this.close$),
      map(([ isLoading, billingInfo, paymentSources ]) => this._getPaymentState(
        isLoading,
        billingInfo,
        paymentSources
      ))
    )),
    distinctUntilChanged()
  );
  resolvedPaymentState$ = this.dialogState$.pipe(
    switchMap((state) => {
      if (state) {
        return this.currentPaymentState$.pipe(
          takeUntil(this.close$),
          filter((s) => s !== 'LOADING')
        );
      } else {
        return of('INITIALIZING');
      }
    }),
    distinctUntilChanged()
  );
  vatRate$ = this.open$.pipe(
    switchMap(() => this.countryMapFilled$.pipe(
      mergeMap((countryMap) => combineLatest([
        this.currentPaymentState$,
        this.currentBillingInfo$,
        this.billingInfoFormState$
      ]).pipe(
        takeUntil(this.close$),
        filter(([ s ]) => s !== 'LOADING'),
        debounceTime(0),
        switchMap(([ paymentState, currentBillingInfo, billingInfoFormState ]) => {

          if (paymentState === 'READY' || paymentState === 'MISSING_PAYMENT_SOURCE') {
            const countryId = currentBillingInfo?.invoiceAddressCountryId || billingInfoFormState.value.invoiceAddressCountryId;
            const country = countryMap[countryId];

            if (!country) {
              return of(21);
            }

            if (country.id === 'CZ') {
              return of(21);
            }

            if (!country.inEu) {
              return of(0);
            }

            if (country.id !== 'CZ' && !!country.inEu && !currentBillingInfo.vatNumber) {
              return of(21);
            }

            if (!!currentBillingInfo.vatNumber) {
              return of(0);
            }

          }

          if (paymentState === 'MISSING_BILLING_INFO') {
            if (billingInfoFormState.value.invoiceAddressCountryId) {
              const country = countryMap[billingInfoFormState.value.invoiceAddressCountryId];

              if (country.id === 'CZ') {
                return of(21);
              }

              if (!country.inEu) {
                return of(0);
              }

              if (country.id !== 'CZ' && !!country.inEu && !billingInfoFormState.value.vatNumber) {
                return of(21);
              }

              if (!!billingInfoFormState.value.vatNumber) {
                return of(0);
              }

            } else {
              return of(undefined);
            }
          }

          return of(undefined);

        })
      ).pipe(takeUntil(this.close$))),
      distinctUntilChanged()
    ))
  );
  showPromoCodePrompt$ = this._paymentEntity.list$().pipe(
    withLatestFrom(this._userEntity.activeClientUser$),
    map(([ p, c ]) => {
      const paymentWithPromo = p?.find(
        ({ status }) => status === PaymentStatuses.Finished // && !!appliedPromoCodeId
      );

      return !paymentWithPromo && !c?.client?.signUpPromoCodeId;
    }),
    distinctUntilChanged()
  );

  // # State resolver
  state = this.$connect({
    open: this.open$,
    dialogState: this.dialogState$,
    countryList: this.countryList$,
    countryMap: this.countryMap$,
    paymentSources: this.paymentSources$,
    currentPaymentState: this.currentPaymentState$,
    resolvedPaymentState: this.resolvedPaymentState$,
    billingInfo: this.billingInfo$,
    stripeCardValid: this.stripeCardValid$,
    currentBillingInfo: this.currentBillingInfo$,
    amountFormValid: this.amountFormValid$,
    topUpAmountFormState: this.topUpAmountFormState$,
    topUpPromoCodeFormState: this.topUpPromoCodeFormState$,
    billingInfoFormState: this.billingInfoFormState$,
    isLoadingEssentialData: this.isLoadingEssentialData$,
    vatRate: this.vatRate$,
    showPromoCodePrompt: this.showPromoCodePrompt$
  });

  private _topUpPaymentValidStream$ = this.onTopUp$.pipe(
    formValueOnValid(this._topUpAmountForm),
    withLatestFrom(
      this._topUpPromoCodeForm.value$,
      this.currentPaymentState$,
      this.paymentSources$,
      this._billingInfoForm.state$,
      this.stripeCardValid$
    )
  );

  // # Action Streams
  private _closeAction$ = this.onClose$.pipe(
    map(() => zefDialogClose({ key: FEATURE_NAME }))
  );

  private _onTopUpRequest$ = this._topUpPaymentValidStream$.pipe(
    map(([
      { amount },
      { promoCode },
      paymentState,
      paymentSources,
      billingFormState,
      creditCardValid,
    ]) => {

      if (paymentState === 'MISSING_BILLING_INFO') {
        if (billingFormState.isValid && creditCardValid) {
          if (billingFormState.value.companyName) {
            return updateBillingInfo(billingFormState.value);
          }
        } else {
          return false as any;
        }
      }
      return paymentRequest({
        amount,
        sourceId: paymentSources?.length
          ? paymentSources[0].id
          : undefined,
        promoCode: !!trim(promoCode) ? trim(promoCode) : undefined
      });
    }),
    filter((d) => !!d)
  );

  private _onTopUpRequestBillingInfoPaymentRequest$ = this._topUpPaymentValidStream$.pipe(
    switchMap(([{ amount }, { promoCode }]) => this._actions$.pipe(
      ofType(updateBillingInfoSuccess),
      take(1),
      takeUntil(this._actions$.pipe(ofType(updateBillingInfoFail))),
      map(() => paymentRequest({ amount, promoCode }))
    ))
  );

  private _onTopUpConfirmPayment$ = this._actions$.pipe(
    ofType(paymentRequest),
    map(() => confirmPayment())
  );

  private _removePaymentSourceAction$ = this.onRemovePaymentSource$.pipe(
    map((d) => removePaymentSource(d))
  );

  private _onConfirmPaymentSuccess$ = this._actions$.pipe(
    ofType(confirmPayment),
    switchMap((action) => this._store.pipe(
      takeUntil(this._actions$.pipe(ofType(paymentRequestFail))),
      select(selectPaymentClientSecret),
      firstAvailable(),
      withLatestFrom(this.currentPaymentState$, this.paymentSources$),
      // happens inside component because it's not possible to send
      // stripe card element through an action
      switchMap(([ secret, paymentState, paymentSources ]) => {

        if (paymentState === 'READY') {
          return this._stripeService
            .confirmCardPayment(secret, {
              payment_method: paymentSources[0].id
            })
            .pipe(
              // TODO: handle error in success
              map((res) => confirmPaymentSuccess(res, action)),
              catchError((err) => of(confirmPaymentFail(err, action)))
            );
        }

        if (paymentState === 'MISSING_PAYMENT_SOURCE' || paymentState === 'MISSING_BILLING_INFO') {
          return this._stripeService
            .confirmCardPayment(secret, {
              payment_method: {
                card: this.card.element
              }
            })
            .pipe(
              // TODO: handle error in success
              map((res) => confirmPaymentSuccess(res, action)),
              catchError((err) => of(confirmPaymentFail(err, action)))
            );
        }

        return of(confirmPaymentFail(undefined, action));
      })
    ))
  );

  constructor(
    private _actions$: Actions,
    private _store: Store<AppState>,
    private _topUpAmountForm: TopUpAmountForm,
    private _topUpPromoCodeForm: TopUpPromoCodeForm,
    private _stripeService: StripeService,
    private _billingInfoForm: TopUpBillingInfoForm,
    private _paymentEntity: PaymentEntity,
    private _userEntity: UserEntity
  ) {
    super();

    // # Dispatcher
    this.$dispatchActions([
      this._closeAction$,
      this._onTopUpRequest$,
      this._onTopUpConfirmPayment$,
      this._onTopUpRequestBillingInfoPaymentRequest$,
      this._onConfirmPaymentSuccess$,
      this._removePaymentSourceAction$
    ]);

  }

  stripeCardChange(e: StripeElementChangeEvent) {
    this.stripeCardValid$.next(e.complete);
  }

  markFormsAsSubmitted() {
    this.topUpFormRef?.triggerSubmit();
    this.billingFormRef?.triggerSubmit()
  }

  private _getPaymentState(
    isLoading: boolean,
    billingInfo: ClientBaseBillingInfo,
    paymentSources: PaymentSource[]
  ) {

    if (isLoading) { return 'LOADING'; }

    // if you have payment source, you have invoiceAddressCountryId
    if (!!paymentSources?.length) {
      return 'READY';
    }

    if (!billingInfo?.invoiceAddressCountryId) {
      return 'MISSING_BILLING_INFO';
    }

    if (!paymentSources?.length) {
      return 'MISSING_PAYMENT_SOURCE';
    }

    console.warn('Unknown top up dialog state.');

  }

}
