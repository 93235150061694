export enum UserDataTypes {
  Editable = 'EDITABLE',
  ReadOnly = 'READ_ONLY',
  Secret = 'SECRET',
  Env = 'ENV'
}

export const UserDataTypeOrderMap = {
  [UserDataTypes.ReadOnly]: 3,
  [UserDataTypes.Editable]: 2,
  [UserDataTypes.Secret]: 1,
  [UserDataTypes.Env]: 0,
};

export class UserData {
  id: string;
  clientId: string;
  content: string;
  created: string;
  lastSync: string;
  deleteOnSync: boolean;
  isSynced: boolean;
  key: string;
  lastUpdate: string;
  projectId: string;
  type: UserDataTypes;
  serviceStackId: string;
  serviceStackName: string;
  sensitive?: boolean;
}

export enum UserDataKeys {
  ApiUrl = 'apiUrl',
  AccessKey = 'accessKeyId',
  Secret = 'secretAccessKey',
  QuotaGBytes = 'quotaGBytes',
  BucketName = 'bucketName',
  ConnectionString = 'connectionString',
  ConnectionStringReplicas = 'connectionStringReplicas',
  ConnectionStringStomp = 'connectionStringStomp',
  ConnectionStringMqtt = 'connectionStringMqtt',
  Hostname = 'hostname',
  Port = 'port',
  PortStomp = 'portStomp',
  PortWsMqtt = 'portWsMqtt',
  PortMqtt = 'portMqtt',
  PortReplicas = 'portReplicas',
  User = 'user',
  Password = 'password',
  ReadOnlyApiKey = 'readOnlyApiKey',
  ApiKey = 'apiKey',
  GrpcPort = 'grpcPort',
  GrpcConnectionString = 'grpcConnectionString',
  MasterKey = 'masterKey',
}
