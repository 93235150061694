import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import {
  switchMap,
  catchError,
  map,
  mergeMap,
  filter,
  withLatestFrom,
  delay
} from 'rxjs/operators';
import { of } from 'rxjs';
import { UserEntity } from '@zerops/zerops/core/user-base';
import { StatisticsEntities, StatisticsGroupBy } from '@zerops/models/resource-statistics';
import { ResourceStatisticsBaseApi } from './resource-statistics-base.api';
import {
  loadCurrentStatistics,
  loadCurrentStatisticsSuccess,
  loadCurrentStatisticsFail,
  loadResourceHistoryStatistics,
  loadResourceHistoryStatisticsSuccess,
  loadResourceHistoryStatisticsFail,
  loadResourceHistoryStatisticsCancel,
  loadResourceHistoryStatisticsUpdate,
  loadCurrentStatisticsUpdate
} from './resource-statistics-base.action';
import { zefWebsocketMessage } from '@zerops/zef/websocket';
import startsWith from 'lodash-es/startsWith';
import { FEATURE_NAME } from './resource-statistics-base.constant';
import { extractBetween } from '@zerops/zef/core';
import { AppState } from '@zerops/zerops/app';
import { select, Store } from '@ngrx/store';
import { historyDataExists } from './resource-statistics-base.utils';
import { selectResourceHistoryData } from './resource-statistics-base.selector';
import { zefRemoveError } from '@zerops/zef/errors';
import { zefRemoveProgress } from '@zerops/zef/progress';

@Injectable()
export class ResourceStatisticsBaseEffect {

  private _activeClientId$ = this._userEntity.activeClientId$.pipe(filter((d) => !!d));

  private _clientIdSetOrChangeLoadCurrentServiceStackStatistics$ = createEffect(() => this._activeClientId$.pipe(
    map((clientId) => loadCurrentStatistics({
      data: {
        clientId,
        groupBy: StatisticsGroupBy.Service,
        statisticsEntity: StatisticsEntities.ServiceStack
      }
    }))
  ));

  private _onLoadCurrentServiceStackStatistics$ = createEffect(() => this._actions$.pipe(
    ofType(loadCurrentStatistics),
    filter(({ data }) => data?.statisticsEntity === StatisticsEntities.ServiceStack),
    switchMap((action) => this._api
      .groupSearchCurrentByClient$(action.data.clientId, action.data.groupBy)
      .pipe(
        map((response) => loadCurrentStatisticsSuccess({ ...response, key: action.data.groupBy }, action)),
        catchError((err) => of(loadCurrentStatisticsFail(err, action)))
      )
    )
  ));

  private _onLoadCurrentContainerStatistics$ = createEffect(() => this._actions$.pipe(
    ofType(loadCurrentStatistics),
    filter(({ data }) => data?.statisticsEntity === StatisticsEntities.Container),
    switchMap((action) => this._api
      .groupSearchCurrentByClient$(action.data.clientId, action.data.groupBy)
      .pipe(
        map((response) => loadCurrentStatisticsSuccess({ ...response, key: action.data.groupBy }, action)),
        catchError((err) => of(loadCurrentStatisticsFail(err, action)))
      )
    )
  ));

  private _onLoadHistoryServiceStackStatistics$ = createEffect(() => this._actions$.pipe(
    ofType(loadResourceHistoryStatistics),
    withLatestFrom(this._store.pipe(select(selectResourceHistoryData)), this._activeClientId$),
    mergeMap(([ action, historyData, clientId ]) => {

      const exists = historyDataExists(action.data, historyData);

      if (exists) { return of(loadResourceHistoryStatisticsCancel()); }

      return this._api.groupSearchHistory$(
        clientId,
        action.data.groupBy,
        action.data.groupRange.timeGroupBy,
        action.data.groupRange.limit,
        action.data.projectId,
        action.data.serviceId,
        action.data.containerId,
        action.data.groupRange.range,
        action.data.groupRange.key
      ).pipe(
        map((response) => loadResourceHistoryStatisticsSuccess({
          ...response,
          timeGroupBy: action.data.groupRange.timeGroupBy,
          groupBy: action.data.groupBy,
          limit: action.data.groupRange.limit,
          from: action.data.groupRange.range.from,
          till: action.data.groupRange.range.to,
          projectId: action.data.projectId,
          serviceId: action.data.serviceId,
          key: action.data.groupRange.key
        }, action)),
        catchError((err) => of(loadResourceHistoryStatisticsFail(err, action)))
      )

    }
  )));

  private _onLoadCurrentStatisticsCancel$ = createEffect(() => this._actions$.pipe(
    ofType(loadResourceHistoryStatisticsCancel),
    delay(0),
    mergeMap(() => [
      zefRemoveError(loadResourceHistoryStatistics.type),
      zefRemoveProgress(loadResourceHistoryStatistics.type)
    ])
  ))

  private _onHistoryStatisticsWebsocketUpdate$ = createEffect(() => this._actions$.pipe(
    ofType(zefWebsocketMessage),
    filter((action) => action.message && startsWith(action.message.subscriptionName, `${FEATURE_NAME}_history-statistics-group`)),
    map((action) => ({ d: action.message.data, subName: action.message.subscriptionName })),
    map(({ d, subName }) => loadResourceHistoryStatisticsUpdate({
      items: d?.update || [],
      timeGroupBy: d?.timeGroupBy,
      groupBy: d?.groupBy,
      limit: d?.limit,
      key: extractBetween('##', '##')(subName)[0],
      projectId: d?.projectId,
      serviceId: d?.serviceId
    }))
  ));

  private _onContainerCurrentStatisticUpdate$ = createEffect(() => this._actions$.pipe(
    ofType(zefWebsocketMessage),
    filter((action) => action.message && startsWith(action.message.subscriptionName, `${FEATURE_NAME}_current-resource-stats`)),
    map((action) => loadCurrentStatisticsUpdate(
      action.message.data.items,
      extractBetween('##', '##')(action.message.subscriptionName)[0] as any)
    )
  ));

  constructor(
    private _api: ResourceStatisticsBaseApi,
    private _actions$: Actions<any>,
    private _userEntity: UserEntity,
    private _store: Store<AppState>
  ) { }

}
