<div class="__scaling-config  c-autoscaling-form-field-scaling-config">

  <div class="__scaling-config_grid">

    <!-- MODE -->
    <div class="__scaling-config_grid-item">

      <div class="__configurator-item  __configurator-item--containers">

        <ng-container *ngIf="mode === modes.Customization && (!serviceStackAutoscalingConfig[serviceStackType]?.horizontal || (serviceStackAutoscalingConfig[serviceStackType]?.horizontal && serviceStackAutoscalingConfig[serviceStackType]?.mode && value.mode === 'NON_HA'))">

          <h4>
            {{ value.mode === 'HA' ? 'Highly available' : 'Single container' }} mode
          </h4>

          <h5
            *ngIf="value.mode === 'NON_HA'"
            class="__ha-info_subtitle">
            Suited for non-essential data or dev environments
          </h5>

          <div
            *ngIf="value.mode === 'HA'"
            class="__ha-info">

            <span class="__ha-info-desc">

              <ng-container *ngIf="serviceStackType === serviceStackTypes.Elasticsearch">
                <div class="__ha-info-desc-grid">
                  <div>{{ serviceStackAutoscalingConfig[serviceStackType]?.HAContainers.appContainers }} containers</div>
                </div>
              </ng-container>

              <ng-container *ngIf="serviceStackType === serviceStackTypes.Typesense">
                <div class="__ha-info-desc-grid">
                  <div>{{ serviceStackAutoscalingConfig[serviceStackType]?.HAContainers.appContainers }} containers</div>
                </div>
              </ng-container>

              <ng-container *ngIf="serviceStackType === serviceStackTypes.MongoDb">
                <div class="__ha-info-desc-grid">
                  <div>{{ serviceStackAutoscalingConfig[serviceStackType]?.HAContainers.appContainers }} containers</div>
                </div>
              </ng-container>

              <ng-container *ngIf="serviceStackType === serviceStackTypes.MariaDb">
                <div class="__ha-info-desc-grid">
                  <div>{{ serviceStackAutoscalingConfig[serviceStackType]?.HAContainers.appContainers }} containers</div>
                  <div><mat-icon>add</mat-icon></div>
                  <div>{{ serviceStackAutoscalingConfig[serviceStackType]?.HAContainers.freeContainers }} free load balancers</div>
                </div>
              </ng-container>

              <ng-container *ngIf="serviceStackType === serviceStackTypes.Redis">
                <div class="__ha-info-desc-grid">
                  <div>{{ serviceStackAutoscalingConfig[serviceStackType]?.HAContainers.appContainers }} containers</div>
                </div>
              </ng-container>

              <ng-container *ngIf="serviceStackType === serviceStackTypes.Valkey">
                <div class="__ha-info-desc-grid">
                  <div>{{ serviceStackAutoscalingConfig[serviceStackType]?.HAContainers.appContainers }} containers</div>
                </div>
              </ng-container>

              <ng-container *ngIf="serviceStackType === serviceStackTypes.Qdrant">
                <div class="__ha-info-desc-grid">
                  <div>{{ serviceStackAutoscalingConfig[serviceStackType]?.HAContainers.appContainers }} containers</div>
                </div>
              </ng-container>

              <ng-container *ngIf="serviceStackType === serviceStackTypes.Nats">
                <div class="__ha-info-desc-grid">
                  <div>{{ serviceStackAutoscalingConfig[serviceStackType]?.HAContainers.appContainers }} containers</div>
                </div>
              </ng-container>

              <ng-container *ngIf="serviceStackType === serviceStackTypes.Kafka">
                <div class="__ha-info-desc-grid">
                  <div>{{ serviceStackAutoscalingConfig[serviceStackType]?.HAContainers.appContainers }} containers</div>
                </div>
              </ng-container>

              <ng-container *ngIf="serviceStackType === serviceStackTypes.RabbitMq">
                <div class="__ha-info-desc-grid">
                  <div>{{ serviceStackAutoscalingConfig[serviceStackType]?.HAContainers.appContainers }} containers</div>
                  <div><mat-icon>add</mat-icon></div>
                  <div>{{ serviceStackAutoscalingConfig[serviceStackType]?.HAContainers.freeContainers }} free load balancers</div>
                </div>
              </ng-container>

              <ng-container *ngIf="serviceStackType === serviceStackTypes.Postgres">
                <div class="__ha-info-desc-grid">
                  <div>{{ serviceStackAutoscalingConfig[serviceStackType]?.HAContainers.appContainers }} containers</div>
                  <div><mat-icon>add</mat-icon></div>
                  <div>{{ serviceStackAutoscalingConfig[serviceStackType]?.HAContainers.freeContainers }} free load balancers</div>
                </div>
              </ng-container>

              <ng-container *ngIf="serviceStackType === serviceStackTypes.SharedStorage">
                <div class="__ha-info-desc-grid">
                  <div>{{ serviceStackAutoscalingConfig[serviceStackType]?.HAContainers.appContainers }} GlusterFS containers</div>
                </div>
              </ng-container>

            </span>

          </div>

        </ng-container>

        @if (serviceStackAutoscalingConfig[serviceStackType]?.mode === false && serviceStackType === serviceStackTypes.Meilisearch) {
          <h4 class="__configurator-item_title">
            Mode
          </h4>

          <div class="__configurator-ha-warning  __configurator-ha-warning--managed-no-ha">

            <h3 class="__configurator-ha-warning_title"><mat-icon>auto_awesome</mat-icon> Single container mode</h3>

            <p class="__configurator-ha-warning_desc">Meilisearch doesn't support horizontal scaling, instead relying on backups.</p>


          </div>
        }

        <ng-container *ngIf="serviceStackAutoscalingConfig[serviceStackType]?.mode && mode === modes.Add">

          <h4 class="__configurator-item_title">
            Mode
          </h4>

          <div class="__configurator-item_config-grid  __configurator-item_config-grid--mode">

            <div>
              <button
                type="button"
                class="__configurator-item_config-mode"
                (click)="setMode('HA', true)"
                [class.is-active]="value.mode === 'HA'">
                <span class="__configurator-item_config-mode-title">Highly Available</span>
                <span class="__configurator-item_config-mode-desc">

                  <ng-container *ngIf="serviceStackType === serviceStackTypes.Elasticsearch">
                    Runs on {{ serviceStackAutoscalingConfig[serviceStackType]?.HAContainers.appContainers }} containers. Suited for production.
                  </ng-container>

                  <ng-container *ngIf="serviceStackType === serviceStackTypes.Typesense">
                    Runs on {{ serviceStackAutoscalingConfig[serviceStackType]?.HAContainers.appContainers }} containers. Suited for production.
                  </ng-container>

                  <ng-container *ngIf="serviceStackType === serviceStackTypes.MongoDb">
                    Runs on {{ serviceStackAutoscalingConfig[serviceStackType]?.HAContainers.appContainers }} containers. Suited for production.
                  </ng-container>

                  <ng-container *ngIf="serviceStackType === serviceStackTypes.MariaDb">
                    Runs on {{ serviceStackAutoscalingConfig[serviceStackType]?.HAContainers.appContainers }} containers with {{ serviceStackAutoscalingConfig[serviceStackType]?.HAContainers.freeContainers }} free load balancers. Suited for production.
                  </ng-container>

                  <ng-container *ngIf="serviceStackType === serviceStackTypes.Redis">
                    Runs on {{ serviceStackAutoscalingConfig[serviceStackType]?.HAContainers.appContainers }} containers. Suited for production.
                  </ng-container>

                  <ng-container *ngIf="serviceStackType === serviceStackTypes.Valkey">
                    Runs on {{ serviceStackAutoscalingConfig[serviceStackType]?.HAContainers.appContainers }} containers. Suited for production.
                  </ng-container>

                  <ng-container *ngIf="serviceStackType === serviceStackTypes.Qdrant">
                    Runs on {{ serviceStackAutoscalingConfig[serviceStackType]?.HAContainers.appContainers }} containers. Suited for production.
                  </ng-container>

                  <ng-container *ngIf="serviceStackType === serviceStackTypes.Nats">
                    Runs on {{ serviceStackAutoscalingConfig[serviceStackType]?.HAContainers.appContainers }} containers. Suited for production.
                  </ng-container>

                  <ng-container *ngIf="serviceStackType === serviceStackTypes.Kafka">
                    Runs on {{ serviceStackAutoscalingConfig[serviceStackType]?.HAContainers.appContainers }} containers. Suited for production.
                  </ng-container>

                  <ng-container *ngIf="serviceStackType === serviceStackTypes.RabbitMq">
                    Runs on {{ serviceStackAutoscalingConfig[serviceStackType]?.HAContainers.appContainers }} containers with {{ serviceStackAutoscalingConfig[serviceStackType]?.HAContainers.freeContainers }} free load balancers. Suited for production.
                  </ng-container>

                  <ng-container *ngIf="serviceStackType === serviceStackTypes.Postgres">
                    Runs on {{ serviceStackAutoscalingConfig[serviceStackType]?.HAContainers.appContainers }} containers with {{ serviceStackAutoscalingConfig[serviceStackType]?.HAContainers.freeContainers }} free load balancers. Suited for production.
                  </ng-container>

                  <ng-container *ngIf="serviceStackType === serviceStackTypes.SharedStorage">
                    Runs on {{ serviceStackAutoscalingConfig[serviceStackType]?.HAContainers.appContainers }} GlusterFS containers. Suited for production.
                  </ng-container>

                </span>
              </button>
            </div>

            <div>
              <button
                type="button"
                (click)="setMode('NON_HA', true)"
                [class.is-active]="value.mode === 'NON_HA'"
                class="__configurator-item_config-mode">

                <span class="__configurator-item_config-mode-title">Single Container</span>

                <span class="__configurator-item_config-mode-desc">Runs on a single container, useful for non-essential data or dev environments.</span>

              </button>
            </div>

          </div>

          <zui-separator
            *ngIf="serviceStackAutoscalingConfig[serviceStackType]?.horizontal && !serviceStackAutoscalingConfig[serviceStackType]?.mode
              || (serviceStackAutoscalingConfig[serviceStackType]?.horizontal
                && serviceStackAutoscalingConfig[serviceStackType]?.mode
                && value?.mode === 'HA')"
            [spacing]="'small'"
            [position]="'start'"
            [size]="'small'">
          </zui-separator>

        </ng-container>

        <ng-container *ngIf="serviceStackAutoscalingConfig[serviceStackType]?.horizontal && !serviceStackAutoscalingConfig[serviceStackType]?.mode
          || (serviceStackAutoscalingConfig[serviceStackType]?.horizontal
            && serviceStackAutoscalingConfig[serviceStackType]?.mode
            && value?.mode === 'HA')">

          <h4 class="__configurator-item_title">

            Containers

            <button
              type="button"
              mat-stroked-button
              (click)="openScalingDialog(
                'CONTAINER',
                value?.horizontalAutoscaling?.minContainerCount || defaultHorizontalAutoscaling.minContainerCount,
                value?.horizontalAutoscaling?.maxContainerCount || defaultHorizontalAutoscaling.maxContainerCount
              )"
              class="c-button c-button--smaller  __configurator-item_title-edt-button">
              <span>Adjust Scaling</span>
            </button>

          </h4>

          <div class="__configurator-item_config-grid">

            <div>

              <div class="__configurator-item_config-range">
                <span class="__configurator-item_config-label">
                  {{ !!value?.horizontalAutoscaling?.minContainerCount
                    && !!value?.horizontalAutoscaling?.maxContainerCount
                    && value?.horizontalAutoscaling?.minContainerCount === value?.horizontalAutoscaling?.maxContainerCount
                    ? 'Stay at'
                    : 'Start With' }}
                </span>
                <span class="__configurator-item_config-value">
                  {{ value?.horizontalAutoscaling?.minContainerCount || defaultHorizontalAutoscaling.minContainerCount }}
                </span>
              </div>

            </div>

            <div>
              <zui-separator [spacing]="'none'" [orientation]="'horizontal'"></zui-separator>
            </div>

            <div>

              @if (!!value?.horizontalAutoscaling?.minContainerCount
                && !!value?.horizontalAutoscaling?.maxContainerCount
                && value?.horizontalAutoscaling?.minContainerCount === value?.horizontalAutoscaling?.maxContainerCount) {
                <p
                  class="u-desc u-text--right  __configurator-item_config-value-disabled">
                  Container scaling <br/>not enabled
                </p>
              } @else {
                <div
                  class="__configurator-item_config-range  __configurator-item_config-range--alt">

                  <span class="__configurator-item_config-label">Scale Up To</span>

                  <span class="__configurator-item_config-value">
                    {{ value?.horizontalAutoscaling?.maxContainerCount || defaultHorizontalAutoscaling?.maxContainerCount }}
                  </span>

                </div>
              }

            </div>

          </div>

          @if (value?.horizontalAutoscaling?.minContainerCount === 1
          || (!value?.horizontalAutoscaling?.minContainerCount && defaultHorizontalAutoscaling?.minContainerCount === 1)) {
            <div class="__configurator-ha-warning">

              <h3 class="__configurator-ha-warning_title"><mat-icon>error_outline</mat-icon> High Availability</h3>

              <p class="__configurator-ha-warning_desc">You can start with 1 container, but you should do so with caution and only for non-critical or dev services. Production grade service should start with at least 2 containers.</p>

              <button
                mat-flat-button
                type="button"
                (click)="setToHa()"
                class="[ c-button  c-button--smaller  c-button--full ]  __configurator-ha-warning_button">
                {{ mode === modes.Add ? 'Set to high availability?' : 'Set to high availability and apply changes?' }}
              </button>

            </div>
          }

        </ng-container>

      </div>

    </div>

    <div class="__scaling-config_grid-separator">
      <mat-icon class="__scaling-config_grid-separator_icon">close</mat-icon>
    </div>

    <!-- RESOURCES -->
    <div class="__scaling-config_grid-item __scaling-config_grid-item--alt">

      <div class="__configurator-item_items-grid">
        <!-- CPU mode -->
        <div>
          <div class="__configurator-item  __configurator-item--cpu-mode">

            <h4 class="__configurator-item_title">
              CPU Mode
            </h4>

            <div class="fx:grid  fx:grid-cols-2  fx:col-gap-1">

              <button
                #sharedCpuModeButtonRef="dynamicPopAnchor"
                [zuiDynamicPopAnchor]="cpuModePopRef.popRef"
                class="__cpu-mode-button"
                type="button"
                (click)="mode === modes.Add
                  ? setCpuMode('SHARED', true)
                  : confirmCpuMode('SHARED', sharedCpuModeButtonRef)"
                [class.is-active]="(value?.verticalAutoscaling?.cpuMode === 'SHARED' || (!value?.verticalAutoscaling?.cpuMode && defaultVerticalAutoscaling.cpuMode === 'SHARED'))"
                mat-button>
                Shared
              </button>

              <button
                #dedicatedCpuModeButtonRef="dynamicPopAnchor"
                (click)="mode === modes.Add
                  ? setCpuMode('DEDICATED', true)
                  : confirmCpuMode('DEDICATED', dedicatedCpuModeButtonRef)"
                [zuiDynamicPopAnchor]="cpuModePopRef.popRef"
                class="__cpu-mode-button"
                type="button"
                [class.is-active]="(value?.verticalAutoscaling?.cpuMode === 'DEDICATED' || (!value?.verticalAutoscaling?.cpuMode && defaultVerticalAutoscaling.cpuMode === 'DEDICATED'))"
                mat-button>
                Dedicated
              </button>

            </div>

            <zui-confirm-pop
              #cpuModePopRef
              [heading]="'Are you sure want to switch CPU mode to ' + cpuModesConfirmTempVal + '?'"
              [confirmButtonText]="'Confirm'"
              [verticalAlign]="'below'"
              [closeButtonText]="'Close'"
              (confirm)="setCpuMode(cpuModesConfirmTempVal, true); cpuModesConfirmTempVal = undefined; cpuModePopRef.popRef.close();">
            </zui-confirm-pop>

          </div>
        </div>

        <!-- CPU -->
        <div>
          <div class="__configurator-item">

            <h4 class="__configurator-item_title">

              CPU Cores

              <button
                type="button"
                mat-stroked-button
                (click)="openScalingDialog(
                  scalingTypes.Cpu,
                  value?.verticalAutoscaling?.minResource?.cpuCoreCount || defaultVerticalAutoscaling?.minResource?.cpuCoreCount,
                  value?.verticalAutoscaling?.maxResource?.cpuCoreCount || defaultVerticalAutoscaling?.maxResource?.cpuCoreCount,
                  value?.verticalAutoscaling?.minFreeResource?.cpuCoreCount,
                  value?.verticalAutoscaling?.minFreeResource?.cpuCorePercent,
                  value?.verticalAutoscaling?.startCpuCoreCount
                )"
                class="c-button c-button--smaller  __configurator-item_title-edt-button">
                <span>Adjust Scaling</span>
              </button>

            </h4>

            <div class="__configurator-item_config-grid">

              <div>

                <div class="__configurator-item_config-range">
                  <span class="__configurator-item_config-label">
                    {{ !!value?.verticalAutoscaling?.minResource?.cpuCoreCount
                      && !!value?.verticalAutoscaling?.maxResource?.cpuCoreCount
                      && value?.verticalAutoscaling?.minResource?.cpuCoreCount === value?.verticalAutoscaling?.maxResource?.cpuCoreCount
                      ? 'Stay at'
                      : 'Start With' }}
                  </span>
                  <span class="__configurator-item_config-value">
                    {{ value?.verticalAutoscaling?.minResource?.cpuCoreCount || defaultVerticalAutoscaling?.minResource?.cpuCoreCount }}<span>{{
                      (value?.verticalAutoscaling?.minResource?.cpuCoreCount === 1
                        || (!value?.verticalAutoscaling?.minResource?.cpuCoreCount && defaultVerticalAutoscaling?.minResource?.cpuCoreCount === 1))
                        ? 'core'
                        : 'cores '
                    }}</span>
                  </span>
                </div>

              </div>

              <div>
                <zui-separator [spacing]="'none'" [orientation]="'horizontal'"></zui-separator>
              </div>

              <div>

                @if (!!value?.verticalAutoscaling?.minResource?.cpuCoreCount
                  && !!value?.verticalAutoscaling?.maxResource?.cpuCoreCount
                  && value?.verticalAutoscaling?.minResource?.cpuCoreCount === value?.verticalAutoscaling?.maxResource?.cpuCoreCount) {
                  <p
                    class="u-desc u-text--right  __configurator-item_config-value-disabled">
                    Cores scaling <br/>not enabled
                  </p>

                } @else {
                  <div class="__configurator-item_config-range  __configurator-item_config-range--alt">

                    <span class="__configurator-item_config-label">Scale Up To</span>

                    <span class="__configurator-item_config-value">
                      {{ value?.verticalAutoscaling?.maxResource?.cpuCoreCount || defaultVerticalAutoscaling?.maxResource?.cpuCoreCount }}<span>{{
                        (value?.verticalAutoscaling?.maxResource?.cpuCoreCount === 1
                          || (!value?.verticalAutoscaling?.maxResource?.cpuCoreCount && defaultVerticalAutoscaling?.maxResource?.cpuCoreCount === 1))
                          ? 'core'
                          : 'cores '
                      }}</span>
                    </span>

                  </div>
                }

              </div>

            </div>

          </div>
        </div>

        <!-- RAM -->
        <div>
          <div class="__configurator-item">

            <h4 class="__configurator-item_title">

              RAM

              <button
                type="button"
                mat-stroked-button
                (click)="openScalingDialog(
                  scalingTypes.Ram,
                  value?.verticalAutoscaling?.minResource?.memoryGBytes || defaultVerticalAutoscaling?.minResource?.memoryGBytes,
                  value?.verticalAutoscaling?.maxResource?.memoryGBytes || defaultVerticalAutoscaling?.maxResource?.memoryGBytes,
                  value?.verticalAutoscaling?.minFreeResource?.memoryGBytes,
                  value?.verticalAutoscaling?.minFreeResource?.memoryPercent
                )"
                class="c-button c-button--smaller  __configurator-item_title-edt-button">
                <span>Adjust Scaling</span>
              </button>

            </h4>

            <div class="__configurator-item_config-grid">

              <div>

                <div class="__configurator-item_config-range">
                  <span class="__configurator-item_config-label">
                    {{ !!value?.verticalAutoscaling?.minResource?.memoryGBytes
                      && !!value?.verticalAutoscaling?.maxResource?.memoryGBytes
                      && value?.verticalAutoscaling?.minResource?.cpuCoreCount === value?.verticalAutoscaling?.maxResource?.cpuCoreCount
                      ? 'Stay at'
                      : 'Start With' }}
                  </span>
                  <span class="__configurator-item_config-value">
                    {{ value?.verticalAutoscaling?.minResource?.memoryGBytes || defaultVerticalAutoscaling?.minResource?.memoryGBytes }}<span>GB</span>
                  </span>
                </div>

              </div>

              <div>
                <zui-separator [spacing]="'none'" [orientation]="'horizontal'"></zui-separator>
              </div>

              <div>

                @if (!!value?.verticalAutoscaling?.minResource?.memoryGBytes
                  && !!value?.verticalAutoscaling?.maxResource?.memoryGBytes
                  && value?.verticalAutoscaling?.minResource?.memoryGBytes === value?.verticalAutoscaling?.maxResource?.memoryGBytes) {
                <p
                  class="u-desc u-text--right  __configurator-item_config-value-disabled">
                  RAM scaling <br/>not enabled
                </p>
                } @else {
                  <div class="__configurator-item_config-range  __configurator-item_config-range--alt">

                    <span class="__configurator-item_config-label">Scale Up To</span>

                    <span class="__configurator-item_config-value">
                      {{ value?.verticalAutoscaling?.maxResource?.memoryGBytes || defaultVerticalAutoscaling?.maxResource?.memoryGBytes }}<span>GB</span>
                    </span>

                  </div>
                }

              </div>

            </div>

          </div>
        </div>

        <!-- Disc -->
        <div>
          <div class="__configurator-item">

            <h4 class="__configurator-item_title">

              DISK

              <button
                type="button"
                mat-stroked-button
                (click)="openScalingDialog(
                  scalingTypes.Disc,
                  value?.verticalAutoscaling?.minResource?.diskGBytes || defaultVerticalAutoscaling?.minResource?.diskGBytes,
                  value?.verticalAutoscaling?.maxResource?.diskGBytes || defaultVerticalAutoscaling?.maxResource?.diskGBytes
                )"
                class="c-button c-button--smaller  __configurator-item_title-edt-button">
                <span>Adjust Scaling</span>
              </button>

            </h4>

            <div class="__configurator-item_config-grid">

              <div>

                <div class="__configurator-item_config-range">
                  <span class="__configurator-item_config-label">
                    {{ !!value?.verticalAutoscaling?.minResource?.diskGBytes
                      && !!value?.verticalAutoscaling?.maxResource?.diskGBytes
                      && value?.verticalAutoscaling?.minResource?.diskGBytes === value?.verticalAutoscaling?.maxResource?.diskGBytes
                      ? 'Stay at'
                      : 'Start With' }}
                  </span>
                  <span class="__configurator-item_config-value">
                    {{ value?.verticalAutoscaling?.minResource?.diskGBytes || defaultVerticalAutoscaling?.minResource?.diskGBytes }}<span>GB</span>
                  </span>
                </div>

              </div>

              <div>
                <zui-separator [spacing]="'none'" [orientation]="'horizontal'"></zui-separator>
              </div>

              <div>

                @if (!!value?.verticalAutoscaling?.minResource?.diskGBytes
                && !!value?.verticalAutoscaling?.maxResource?.diskGBytes
                && value?.verticalAutoscaling?.minResource?.diskGBytes === value?.verticalAutoscaling?.maxResource?.diskGBytes) {
                  <p
                    class="u-desc u-text--right  __configurator-item_config-value-disabled">
                    Disk scaling <br/>not enabled
                  </p>
                } @else {
                  <div
                    class="__configurator-item_config-range  __configurator-item_config-range--alt">

                    <span class="__configurator-item_config-label">Scale Up To</span>

                    <span class="__configurator-item_config-value">
                      {{ value?.verticalAutoscaling?.maxResource?.diskGBytes || defaultVerticalAutoscaling?.maxResource?.diskGBytes }}<span>GB</span>
                    </span>

                  </div>
                }
              </div>

            </div>

          </div>
        </div>

      </div>

    </div>

  </div>
</div>

<zef-dialog
  [open]="scalingDialogData?.open"
  (closeTrigger)="closeScalingDialog()">
  <div class="__scaling-dialog-content">

    <zef-scrollbar maxHeight="80vh">
      <mat-card class="__scaling-dialog-card">
        <zef-form>

          <!-- info -->
          <div class="__scaling-dialog-card_info">

            <ng-container *ngIf="serviceStackType === serviceStackTypes.NodeJs">

              <ng-container *ngIf="scalingDialogData.type === scalingTypes.Cpu">

                <h3 class="__scaling-dialog-card_title">Node.js vertical CPU cores scaling</h3>

                <p class="u-desc __scaling-dialog-card_desc">
                  Node.js is a single thread runtime, and unless you use tools like <a href="https://pm2.keymetrics.io/" target="_blank">pm2</a>, you should use a single core.
                </p>

              </ng-container>

              <ng-container *ngIf="scalingDialogData.type === scalingTypes.Ram">

                <h3 class="__scaling-dialog-card_title">Node.js vertical RAM scaling</h3>

                <p class="u-desc __scaling-dialog-card_desc">
                  Node.js is not able to scale up memory on the fly without restarting the process,
                  so it's advisable to start with enough RAM to run your most expensive operations (together with --max-old-space-size switch) and set max to the same value.
                </p>

              </ng-container>

              <ng-container *ngIf="scalingDialogData.type === scalingTypes.Disc">

                <h3 class="__scaling-dialog-card_title">Node.js vertical Disk scaling</h3>

                <p class="u-desc  __scaling-dialog-card_desc">
                  The disk space is automatically scaled up or down without restarting the process.
                  You, however, should not use the local container disk to store your files as the containers are volatile,
                  and their data are not mirrored across the cluster nor backup-ed. Use one of the Zerops storage services to keep your files permanently.
                </p>

              </ng-container>

              <ng-container *ngIf="scalingDialogData.type === scalingTypes.Container">

                <h3 class="__scaling-dialog-card_title">Node.js horizontal scaling</h3>

                <p class="u-desc __scaling-dialog-card_desc">
                  The best way to scale Node.js is by adding and removing containers, start with at least two to achieve high availability.
                  Remember, however, that your application needs to be prepared for this.
                </p>

              </ng-container>

            </ng-container>

            <ng-container *ngIf="serviceStackType === serviceStackTypes.Bun">

              <ng-container *ngIf="scalingDialogData.type === scalingTypes.Cpu">

                <h3 class="__scaling-dialog-card_title">Bun vertical CPU cores scaling</h3>

                <p class="u-desc __scaling-dialog-card_desc">
                  Bun is a single thread runtime, and unless you use tools like <a href="https://pm2.keymetrics.io/" target="_blank">pm2</a>, you should use a single core.
                </p>

              </ng-container>

              <ng-container *ngIf="scalingDialogData.type === scalingTypes.Ram">

                <h3 class="__scaling-dialog-card_title">Bun vertical RAM scaling</h3>

                <p class="u-desc __scaling-dialog-card_desc">
                  Bun is not able to scale up memory on the fly without restarting the process,
                  so it's advisable to start with enough RAM to run your most expensive operations (together with --max-old-space-size switch) and set max to the same value.
                </p>

              </ng-container>

              <ng-container *ngIf="scalingDialogData.type === scalingTypes.Disc">

                <h3 class="__scaling-dialog-card_title">Bun vertical Disk scaling</h3>

                <p class="u-desc  __scaling-dialog-card_desc">
                  The disk space is automatically scaled up or down without restarting the process.
                  You, however, should not use the local container disk to store your files as the containers are volatile,
                  and their data are not mirrored across the cluster nor backup-ed. Use one of the Zerops storage services to keep your files permanently.
                </p>

              </ng-container>

              <ng-container *ngIf="scalingDialogData.type === scalingTypes.Container">

                <h3 class="__scaling-dialog-card_title">Bun horizontal scaling</h3>

                <p class="u-desc __scaling-dialog-card_desc">
                  The best way to scale Bun is by adding and removing containers, start with at least two to achieve high availability.
                  Remember, however, that your application needs to be prepared for this.
                </p>

              </ng-container>

            </ng-container>

            <ng-container *ngIf="serviceStackType === serviceStackTypes.Deno">

              <ng-container *ngIf="scalingDialogData.type === scalingTypes.Cpu">

                <h3 class="__scaling-dialog-card_title">Deno vertical CPU cores scaling</h3>

                <p class="u-desc __scaling-dialog-card_desc">
                  Deno uses a single-threaded event loop model, similar to Node.js, so in most cases, a single core is sufficient. However, certain workloads can benefit from multiple instances across multiple cores.
                </p>

              </ng-container>

              <ng-container *ngIf="scalingDialogData.type === scalingTypes.Ram">

                <h3 class="__scaling-dialog-card_title">Deno vertical RAM scaling</h3>

                <p class="u-desc __scaling-dialog-card_desc">
                  Deno does not dynamically scale memory on the fly without restarting the process. It's advisable to allocate enough RAM to handle your application's peak memory usage and set appropriate limits.
                </p>

              </ng-container>

              <ng-container *ngIf="scalingDialogData.type === scalingTypes.Disc">

                <h3 class="__scaling-dialog-card_title">Bun vertical Disk scaling</h3>

                <p class="u-desc  __scaling-dialog-card_desc">
                  The disk space is automatically scaled up or down without restarting the process.
                  You, however, should not use the local container disk to store your files as the containers are volatile,
                  and their data are not mirrored across the cluster nor backup-ed. Use one of the Zerops storage services to keep your files permanently.
                </p>

              </ng-container>

              <ng-container *ngIf="scalingDialogData.type === scalingTypes.Container">

                <h3 class="__scaling-dialog-card_title">Deno horizontal scaling</h3>

                <p class="u-desc __scaling-dialog-card_desc">
                  The best way to scale Deno is by adding and removing containers, start with at least two to achieve high availability.
                  Remember, however, that your application needs to be prepared for this.
                </p>

              </ng-container>

            </ng-container>

            <ng-container *ngIf="serviceStackType === serviceStackTypes.Java">

              <ng-container *ngIf="scalingDialogData.type === scalingTypes.Cpu">

                <h3 class="__scaling-dialog-card_title">Java vertical CPU cores scaling</h3>

                <p class="u-desc __scaling-dialog-card_desc">
                  Java uses a multi-threaded model with support for concurrency, making it capable of utilizing multiple CPU cores effectively. For compute-intensive applications, scaling vertically by adding more cores can significantly improve performance.
                </p>

              </ng-container>

              <ng-container *ngIf="scalingDialogData.type === scalingTypes.Ram">

                <h3 class="__scaling-dialog-card_title">Java vertical RAM scaling</h3>

                <p class="u-desc __scaling-dialog-card_desc">
                  Java applications typically run on the Java Virtual Machine (JVM), which can manage memory dynamically within the limits set at startup. To ensure optimal performance, allocate sufficient RAM to handle peak memory usage and configure JVM options appropriately.
                </p>

              </ng-container>

              <ng-container *ngIf="scalingDialogData.type === scalingTypes.Disc">

                <h3 class="__scaling-dialog-card_title">Bun vertical Disk scaling</h3>

                <p class="u-desc  __scaling-dialog-card_desc">
                  The disk space is automatically scaled up or down without restarting the process.
                  You, however, should not use the local container disk to store your files as the containers are volatile,
                  and their data are not mirrored across the cluster nor backup-ed. Use one of the Zerops storage services to keep your files permanently.
                </p>

              </ng-container>

              <ng-container *ngIf="scalingDialogData.type === scalingTypes.Container">

                <h3 class="__scaling-dialog-card_title">Deno horizontal scaling</h3>

                <p class="u-desc __scaling-dialog-card_desc">
                  The best way to scale Java applications is by adding and removing containers. Start with at least two to achieve high availability. However, ensure that your application is designed to handle distributed workloads and can manage state across multiple instances. While vertical scaling by adding more CPU cores is effective, horizontal scaling provides better fault tolerance and scalability for high-availability systems.
                </p>

              </ng-container>

            </ng-container>

            <ng-container *ngIf="serviceStackType === serviceStackTypes.DotNet">

              <ng-container *ngIf="scalingDialogData.type === scalingTypes.Cpu">

                <h3 class="__scaling-dialog-card_title">.NET vertical CPU cores scaling</h3>

                <p class="u-desc  __scaling-dialog-card_desc">
                  Zerops automatically adjusts the number of cores based on a modifiable threshold of free resources. Unless your application can handle or utilize more than one CPU core, you should limit the scaling range to a single core. When container scaling is active and Zerops reaches the maximum number of cores, it will begin to scale up by adding new containers.
                </p>

              </ng-container>

              <ng-container *ngIf="scalingDialogData.type === scalingTypes.Ram">

                <h3 class="__scaling-dialog-card_title">.NET vertical RAM scaling</h3>

                <p class="u-desc __scaling-dialog-card_desc">
                  Zerops automatically adjusts the amount of RAM within a user-defined range based on a modifiable threshold of free resources. If your application's RAM usage is consistent and predictable, setting a specific upper limit on RAM can help manage resource allocation efficiently. When RAM scaling is active and the system approaches the maximum allocated RAM, Zerops focuses on optimizing resource usage within the existing configuration, without adding new containers.
                </p>

              </ng-container>

              <ng-container *ngIf="scalingDialogData.type === scalingTypes.Disc">

                <h3 class="__scaling-dialog-card_title">.NET vertical Disk scaling</h3>

                <p class="u-desc __scaling-dialog-card_desc">
                  The disk space is automatically scaled up or down without restarting the process.
                  You, however, should not use the local container disk to store your files as the containers are volatile,
                  and their data are not mirrored across the cluster nor backup-ed. Use one of the Zerops storage services to keep your files permanently.
                </p>

              </ng-container>

              <ng-container *ngIf="scalingDialogData.type === scalingTypes.Container">

                <h3 class="__scaling-dialog-card_title">.NET horizontal scaling</h3>

                <p class="u-desc __scaling-dialog-card_desc">
                  The best way to scale .NET is by adding and removing containers, start with at least two to achieve high availability.
                  Remember, however, that your application needs to be prepared for this.
                </p>

              </ng-container>

            </ng-container>

            <ng-container *ngIf="serviceStackType === serviceStackTypes.Golang">

              <ng-container *ngIf="scalingDialogData.type === scalingTypes.Cpu">

                <h3 class="__scaling-dialog-card_title">Golang vertical CPU cores scaling</h3>

                <p class="u-desc __scaling-dialog-card_desc">
                  Zerops automatically adjusts the number of cores based on a modifiable threshold of free resources. Unless your application can handle or utilize more than one CPU core, you should limit the scaling range to a single core. When container scaling is active and Zerops reaches the maximum number of cores, it will begin to scale up by adding new containers.
                </p>

              </ng-container>

              <ng-container *ngIf="scalingDialogData.type === scalingTypes.Ram">

                <h3 class="__scaling-dialog-card_title">Golang vertical RAM scaling</h3>

                <p class="u-desc  __scaling-dialog-card_desc">
                  Zerops automatically adjusts the amount of RAM within a user-defined range based on a modifiable threshold of free resources. If your application's RAM usage is consistent and predictable, setting a specific upper limit on RAM can help manage resource allocation efficiently. When RAM scaling is active and the system approaches the maximum allocated RAM, Zerops focuses on optimizing resource usage within the existing configuration, without adding new containers.
                </p>

              </ng-container>

              <ng-container *ngIf="scalingDialogData.type === scalingTypes.Disc">

                <h3 class="__scaling-dialog-card_title">Golang vertical Disk scaling</h3>

                <p class="u-desc  __scaling-dialog-card_desc">
                  The disk space is automatically scaled up or down without restarting the process.
                  You, however, should not use the local container disk to store your files as the containers are volatile,
                  and their data are not mirrored across the cluster nor backup-ed. Use one of the Zerops storage services to keep your files permanently.
                </p>

              </ng-container>

              <ng-container *ngIf="scalingDialogData.type === scalingTypes.Container">

                <h3 class="__scaling-dialog-card_title">Golang horizontal scaling</h3>

                <p class="u-desc __scaling-dialog-card_desc">
                  The best way to scale Golang is by adding and removing containers, start with at least two to achieve high availability.
                  Remember, however, that your application needs to be prepared for this.
                </p>

              </ng-container>

            </ng-container>

            <ng-container *ngIf="serviceStackType === serviceStackTypes.Python">

              <ng-container *ngIf="scalingDialogData.type === scalingTypes.Cpu">

                <h3 class="__scaling-dialog-card_title">Python vertical CPU cores scaling</h3>

                <p class="u-desc  __scaling-dialog-card_desc">
                  Zerops automatically adjusts the number of cores based on a modifiable threshold of free resources. Unless your application can handle or utilize more than one CPU core, you should limit the scaling range to a single core. When container scaling is active and Zerops reaches the maximum number of cores, it will begin to scale up by adding new containers.
                </p>

              </ng-container>

              <ng-container *ngIf="scalingDialogData.type === scalingTypes.Ram">

                <h3 class="__scaling-dialog-card_title">Python vertical RAM scaling</h3>

                <p class="u-desc __scaling-dialog-card_desc">
                  Zerops automatically adjusts the amount of RAM within a user-defined range based on a modifiable threshold of free resources. If your application's RAM usage is consistent and predictable, setting a specific upper limit on RAM can help manage resource allocation efficiently. When RAM scaling is active and the system approaches the maximum allocated RAM, Zerops focuses on optimizing resource usage within the existing configuration, without adding new containers.
                </p>

              </ng-container>

              <ng-container *ngIf="scalingDialogData.type === scalingTypes.Disc">

                <h3 class="__scaling-dialog-card_title">Python vertical Disk scaling</h3>

                <p class="u-desc __scaling-dialog-card_desc">
                  The disk space is automatically scaled up or down without restarting the process.
                  You, however, should not use the local container disk to store your files as the containers are volatile,
                  and their data are not mirrored across the cluster nor backup-ed. Use one of the Zerops storage services to keep your files permanently.
                </p>

              </ng-container>

              <ng-container *ngIf="scalingDialogData.type === scalingTypes.Container">

                <h3 class="__scaling-dialog-card_title">Python horizontal scaling</h3>

                <p class="u-desc __scaling-dialog-card_desc">
                  The best way to scale Python is by adding and removing containers, start with at least two to achieve high availability.
                  Remember, however, that your application needs to be prepared for this.
                </p>

              </ng-container>

            </ng-container>

            <ng-container *ngIf="serviceStackType === serviceStackTypes.Rust">

              <ng-container *ngIf="scalingDialogData.type === scalingTypes.Cpu">

                <h3 class="__scaling-dialog-card_title">Rust vertical CPU cores scaling</h3>

                <p class="u-desc __scaling-dialog-card_desc">
                  Zerops automatically adjusts the number of cores based on a modifiable threshold of free resources. Unless your application can handle or utilize more than one CPU core, you should limit the scaling range to a single core. When container scaling is active and Zerops reaches the maximum number of cores, it will begin to scale up by adding new containers.
                </p>

              </ng-container>

              <ng-container *ngIf="scalingDialogData.type === scalingTypes.Ram">

                <h3 class="__scaling-dialog-card_title">Rust vertical RAM scaling</h3>

                <p class="u-desc __scaling-dialog-card_desc">
                  Zerops automatically adjusts the amount of RAM within a user-defined range based on a modifiable threshold of free resources. If your application's RAM usage is consistent and predictable, setting a specific upper limit on RAM can help manage resource allocation efficiently. When RAM scaling is active and the system approaches the maximum allocated RAM, Zerops focuses on optimizing resource usage within the existing configuration, without adding new containers.
                </p>

              </ng-container>

              <ng-container *ngIf="scalingDialogData.type === scalingTypes.Disc">

                <h3 class="__scaling-dialog-card_title">Rust vertical Disk scaling</h3>

                <p class="u-desc __scaling-dialog-card_desc">
                  The disk space is automatically scaled up or down without restarting the process.
                  You, however, should not use the local container disk to store your files as the containers are volatile,
                  and their data are not mirrored across the cluster nor backup-ed. Use one of the Zerops storage services to keep your files permanently.
                </p>

              </ng-container>

              <ng-container *ngIf="scalingDialogData.type === scalingTypes.Container">

                <h3 class="__scaling-dialog-card_title">Rust horizontal scaling</h3>

                <p class="u-desc __scaling-dialog-card_desc">
                  The best way to scale Rust is by adding and removing containers, start with at least two to achieve high availability.
                  Remember, however, that your application needs to be prepared for this.
                </p>

              </ng-container>

            </ng-container>

            <ng-container *ngIf="serviceStackType === serviceStackTypes.Ubuntu">

              <ng-container *ngIf="scalingDialogData.type === 'CPU'">

                <h3 class="__scaling-dialog-card_title">Ubuntu vertical cores scaling</h3>

                <p class="u-desc __scaling-dialog-card_desc">
                  Zerops automatically adjusts the number of cores based on a modifiable threshold of free resources. Unless your application can handle or utilize more than one CPU core, you should limit the scaling range to a single core. When container scaling is active and Zerops reaches the maximum number of cores, it will begin to scale up by adding new containers.
                </p>

              </ng-container>

              <ng-container *ngIf="scalingDialogData.type === 'RAM'">

                <h3 class="__scaling-dialog-card_title">Ubuntu vertical RAM scaling</h3>

                <p class="u-desc __scaling-dialog-card_desc">
                  Zerops automatically adjusts the amount of RAM within a user-defined range based on a modifiable threshold of free resources. If your application's RAM usage is consistent and predictable, setting a specific upper limit on RAM can help manage resource allocation efficiently. When RAM scaling is active and the system approaches the maximum allocated RAM, Zerops focuses on optimizing resource usage within the existing configuration, without adding new containers.
                </p>

              </ng-container>

              <ng-container *ngIf="scalingDialogData.type === 'DISC'">

                <h3 class="__scaling-dialog-card_title">Ubuntu vertical Disk scaling</h3>

                <p class="u-desc __scaling-dialog-card_desc">
                  The disk space is automatically scaled up or down without restarting the process.
                  You, however, should not use the local container disk to store your files as the containers are volatile,
                  and their data are not mirrored across the cluster nor backup-ed. Use one of the Zerops storage services to keep your files permanently.
                </p>

              </ng-container>

              <ng-container *ngIf="scalingDialogData.type === 'CONTAINER'">

                <h3 class="__scaling-dialog-card_title">Ubuntu horizontal scaling</h3>

                <p class="u-desc __scaling-dialog-card_desc">
                  Start with at least two to achieve high availability. Remember, however, that your application needs to be prepared for this.
                </p>

              </ng-container>

            </ng-container>

            <ng-container *ngIf="serviceStackType === serviceStackTypes.Alpine">

              <ng-container *ngIf="scalingDialogData.type === 'CPU'">

                <h3 class="__scaling-dialog-card_title">Alpine vertical cores scaling</h3>

                <p class="u-desc __scaling-dialog-card_desc">
                  Zerops automatically adjusts the number of cores based on a modifiable threshold of free resources. Unless your application can handle or utilize more than one CPU core, you should limit the scaling range to a single core. When container scaling is active and Zerops reaches the maximum number of cores, it will begin to scale up by adding new containers.
                </p>

              </ng-container>

              <ng-container *ngIf="scalingDialogData.type === 'RAM'">

                <h3 class="__scaling-dialog-card_title">Alpine vertical RAM scaling</h3>

                <p class="u-desc __scaling-dialog-card_desc">
                  Zerops automatically adjusts the amount of RAM within a user-defined range based on a modifiable threshold of free resources. If your application's RAM usage is consistent and predictable, setting a specific upper limit on RAM can help manage resource allocation efficiently. When RAM scaling is active and the system approaches the maximum allocated RAM, Zerops focuses on optimizing resource usage within the existing configuration, without adding new containers.
                </p>

              </ng-container>

              <ng-container *ngIf="scalingDialogData.type === 'DISC'">

                <h3 class="__scaling-dialog-card_title">Alpine vertical Disk scaling</h3>

                <p class="u-desc __scaling-dialog-card_desc">
                  The disk space is automatically scaled up or down without restarting the process.
                  You, however, should not use the local container disk to store your files as the containers are volatile,
                  and their data are not mirrored across the cluster nor backup-ed. Use one of the Zerops storage services to keep your files permanently.
                </p>

              </ng-container>

              <ng-container *ngIf="scalingDialogData.type === 'CONTAINER'">

                <h3 class="__scaling-dialog-card_title">Alpine horizontal scaling</h3>

                <p class="u-desc __scaling-dialog-card_desc">
                  Start with at least two to achieve high availability. Remember, however, that your application needs to be prepared for this.
                </p>

              </ng-container>

            </ng-container>

            <ng-container *ngIf="serviceStackType === serviceStackTypes.Docker">

              <ng-container *ngIf="scalingDialogData.type === 'CPU'">

                <h3 class="__scaling-dialog-card_title">Docker cores configuration</h3>

                <p class="u-desc __scaling-dialog-card_desc">
                  Virtual machines do not support vertical scaling, set the number of cores your Docker images require.
                </p>

              </ng-container>

              <ng-container *ngIf="scalingDialogData.type === 'RAM'">

                <h3 class="__scaling-dialog-card_title">Docker RAM configuration</h3>

                <p class="u-desc __scaling-dialog-card_desc">
                  Virtual machines do not support vertical scaling, set the amount of RAM your Docker images require.
                </p>

              </ng-container>

              <ng-container *ngIf="scalingDialogData.type === 'DISC'">

                <h3 class="__scaling-dialog-card_title">Docker Disk configration</h3>

                <p class="u-desc __scaling-dialog-card_desc">
                  Virtual machines do not support vertical scaling, set the amount of Disk your Docker images require.
                  You, however, should not use the local disk to store your files as the containers are volatile,
                  and their data are not mirrored across the cluster nor backup-ed. Use one of the Zerops storage services to keep your files permanently.
                </p>

              </ng-container>

              <ng-container *ngIf="scalingDialogData.type === 'CONTAINER'">

                <h3 class="__scaling-dialog-card_title">Docker horizontal scaling</h3>

                <p class="u-desc __scaling-dialog-card_desc">
                  Set the amount of identical instances of your Docker images
                </p>

              </ng-container>

            </ng-container>

            <ng-container *ngIf="serviceStackType === serviceStackTypes.Nginx">

              <ng-container *ngIf="scalingDialogData.type === scalingTypes.Cpu">

                <h3 class="__scaling-dialog-card_title">Nginx vertical cores scaling</h3>

                <p class="u-desc __scaling-dialog-card_desc">
                  Zerops automatically adjusts the number of cores based on a modifiable threshold of free resources. Unless your application can handle or utilize more than one CPU core, you should limit the scaling range to a single core. When container scaling is active and Zerops reaches the maximum number of cores, it will begin to scale up by adding new containers.
                </p>

              </ng-container>

              <ng-container *ngIf="scalingDialogData.type === scalingTypes.Ram">

                <h3 class="__scaling-dialog-card_title">Nginx vertical RAM scaling</h3>

                <p class="u-desc __scaling-dialog-card_desc">
                  Zerops automatically adjusts the amount of RAM within a user-defined range based on a modifiable threshold of free resources. If your application's RAM usage is consistent and predictable, setting a specific upper limit on RAM can help manage resource allocation efficiently. When RAM scaling is active and the system approaches the maximum allocated RAM, Zerops focuses on optimizing resource usage within the existing configuration, without adding new containers.
                </p>

              </ng-container>

              <ng-container *ngIf="scalingDialogData.type === scalingTypes.Disc">

                <h3 class="__scaling-dialog-card_title">Nginx vertical Disk scaling</h3>

                <p class="u-desc __scaling-dialog-card_desc">
                  The disk space is automatically scaled up or down as the data on the disk increases or decreases.
                </p>

              </ng-container>

              <ng-container *ngIf="scalingDialogData.type === scalingTypes.Container">

                <h3 class="__scaling-dialog-card_title">Nginx horizontal scaling</h3>

                <p class="u-desc __scaling-dialog-card_desc">
                  The best way to scale Nginx is by adding and removing containers, start with at least two to achieve high availability.
                  Remember, however, that your application needs to be prepared for this.
                </p>

              </ng-container>

            </ng-container>

            <ng-container *ngIf="serviceStackType === serviceStackTypes.PhpApache">

              <ng-container *ngIf="scalingDialogData.type === scalingTypes.Cpu">

                <h3 class="__scaling-dialog-card_title">PHP+Apache vertical cores scaling</h3>

                <p class="u-desc __scaling-dialog-card_desc">
                  Zerops automatically adjusts the number of cores based on a modifiable threshold of free resources. Unless your application can handle or utilize more than one CPU core, you should limit the scaling range to a single core. When container scaling is active and Zerops reaches the maximum number of cores, it will begin to scale up by adding new containers.
                </p>

              </ng-container>

              <ng-container *ngIf="scalingDialogData.type === scalingTypes.Ram">

                <h3 class="__scaling-dialog-card_title">PHP+Apache vertical RAM scaling</h3>

                <p class="u-desc __scaling-dialog-card_desc">
                  Zerops automatically adjusts the amount of RAM within a user-defined range based on a modifiable threshold of free resources. If your application's RAM usage is consistent and predictable, setting a specific upper limit on RAM can help manage resource allocation efficiently. When RAM scaling is active and the system approaches the maximum allocated RAM, Zerops focuses on optimizing resource usage within the existing configuration, without adding new containers.
                </p>

              </ng-container>

              <ng-container *ngIf="scalingDialogData.type === scalingTypes.Disc">

                <h3 class="__scaling-dialog-card_title">PHP+Apache vertical Disk scaling</h3>

                <p class="u-desc __scaling-dialog-card_desc">
                  The disk space is automatically scaled up or down as the data on the disk increases or decreases.
                </p>

              </ng-container>

              <ng-container *ngIf="scalingDialogData.type === scalingTypes.Container">

                <h3 class="__scaling-dialog-card_title">PHP+Apache horizontal scaling</h3>

                <p class="u-desc __scaling-dialog-card_desc">
                  The best way to scale PHP+Apache is by adding and removing containers, start with at least two to achieve high availability.
                  Remember, however, that your application needs to be prepared for this.
                </p>

              </ng-container>

            </ng-container>

            <ng-container *ngIf="serviceStackType === serviceStackTypes.PhpNginx">

              <ng-container *ngIf="scalingDialogData.type === scalingTypes.Cpu">

                <h3 class="__scaling-dialog-card_title">PHP+Nginx vertical cores scaling</h3>

                <p class="u-desc __scaling-dialog-card_desc">
                  Zerops automatically adjusts the number of cores based on a modifiable threshold of free resources. Unless your application can handle or utilize more than one CPU core, you should limit the scaling range to a single core. When container scaling is active and Zerops reaches the maximum number of cores, it will begin to scale up by adding new containers.
                </p>

              </ng-container>

              <ng-container *ngIf="scalingDialogData.type === scalingTypes.Ram">

                <h3 class="__scaling-dialog-card_title">PHP+Nginx vertical RAM scaling</h3>

                <p class="u-desc __scaling-dialog-card_desc">
                  Zerops automatically adjusts the amount of RAM within a user-defined range based on a modifiable threshold of free resources. If your application's RAM usage is consistent and predictable, setting a specific upper limit on RAM can help manage resource allocation efficiently. When RAM scaling is active and the system approaches the maximum allocated RAM, Zerops focuses on optimizing resource usage within the existing configuration, without adding new containers.
                </p>

              </ng-container>

              <ng-container *ngIf="scalingDialogData.type === scalingTypes.Disc">

                <h3 class="__scaling-dialog-card_title">PHP+Nginx vertical Disk scaling</h3>

                <p class="u-desc __scaling-dialog-card_desc">
                  The disk space is automatically scaled up or down as the data on the disk increases or decreases.
                </p>

              </ng-container>

              <ng-container *ngIf="scalingDialogData.type === scalingTypes.Container">

                <h3 class="__scaling-dialog-card_title">PHP+Nginx horizontal scaling</h3>

                <p class="u-desc __scaling-dialog-card_desc">
                  The best way to scale PHP+Nginx is by adding and removing containers, start with at least two to achieve high availability.
                  Remember, however, that your application needs to be prepared for this.
                </p>

              </ng-container>

            </ng-container>

            <ng-container *ngIf="serviceStackType === serviceStackTypes.Static">

              <ng-container *ngIf="scalingDialogData.type === scalingTypes.Cpu">

                <h3 class="__scaling-dialog-card_title">Static app vertical cores scaling</h3>

                <p class="u-desc __scaling-dialog-card_desc">
                  Zerops automatically adjusts the number of cores based on a modifiable threshold of free resources. Unless your application can handle or utilize more than one CPU core, you should limit the scaling range to a single core. When container scaling is active and Zerops reaches the maximum number of cores, it will begin to scale up by adding new containers.
                </p>

              </ng-container>

              <ng-container *ngIf="scalingDialogData.type === scalingTypes.Ram">

                <h3 class="__scaling-dialog-card_title">Static app vertical RAM scaling</h3>

                <p class="u-desc __scaling-dialog-card_desc">
                  Zerops automatically adjusts the amount of RAM within a user-defined range based on a modifiable threshold of free resources. If your application's RAM usage is consistent and predictable, setting a specific upper limit on RAM can help manage resource allocation efficiently. When RAM scaling is active and the system approaches the maximum allocated RAM, Zerops focuses on optimizing resource usage within the existing configuration, without adding new containers.
                </p>

              </ng-container>

              <ng-container *ngIf="scalingDialogData.type === scalingTypes.Disc">

                <h3 class="__scaling-dialog-card_title">Static app vertical Disk scaling</h3>

                <p class="u-desc __scaling-dialog-card_desc">
                  The disk space is automatically scaled up or down as the data on the disk increases or decreases.
                </p>

              </ng-container>

              <ng-container *ngIf="scalingDialogData.type === scalingTypes.Container">

                <h3 class="__scaling-dialog-card_title">Static app horizontal scaling</h3>

                <p class="u-desc __scaling-dialog-card_desc">
                  The best way to scale Static app is by adding and removing containers, start with at least two to achieve high availability.
                  Remember, however, that your application needs to be prepared for this.
                </p>

              </ng-container>

            </ng-container>

            <ng-container *ngIf="serviceStackType === serviceStackTypes.Elasticsearch">

              <ng-container *ngIf="scalingDialogData.type === scalingTypes.Cpu">

                <h3 class="__scaling-dialog-card_title">Elasticsearch vertical cores scaling</h3>

                <p class="u-desc __scaling-dialog-card_desc">
                  Zerops automatically adjusts the number of cores based on a modifiable threshold of free resources. Unless your application can handle or utilize more than one CPU core, you should limit the scaling range to a single core. When container scaling is active and Zerops reaches the maximum number of cores, it will begin to scale up by adding new containers.
                </p>

              </ng-container>

              <ng-container *ngIf="scalingDialogData.type === scalingTypes.Ram">

                <h3 class="__scaling-dialog-card_title">Elasticsearch vertical RAM scaling</h3>

                <p class="u-desc __scaling-dialog-card_desc">
                  Zerops automatically adjusts the amount of RAM within a user-defined range based on a modifiable threshold of free resources. If your application's RAM usage is consistent and predictable, setting a specific upper limit on RAM can help manage resource allocation efficiently. When RAM scaling is active and the system approaches the maximum allocated RAM, Zerops focuses on optimizing resource usage within the existing configuration, without adding new containers.
                </p>

              </ng-container>

              <ng-container *ngIf="scalingDialogData.type === scalingTypes.Disc">

                <h3 class="__scaling-dialog-card_title">Elasticsearch vertical Disk scaling</h3>

                <p class="u-desc __scaling-dialog-card_desc">
                  The disk space is automatically scaled up or down as the data on the disk increases or decreases.
                </p>

              </ng-container>

            </ng-container>

            <ng-container *ngIf="serviceStackType === serviceStackTypes.Meilisearch">

              <ng-container *ngIf="scalingDialogData.type === scalingTypes.Cpu">

                <h3 class="__scaling-dialog-card_title">Meilisearch vertical cores scaling</h3>

                <p class="u-desc __scaling-dialog-card_desc">
                  Zerops automatically adjusts the number of cores based on a modifiable threshold of free resources. Unless your application can handle or utilize more than one CPU core, you should limit the scaling range to a single core. When container scaling is active and Zerops reaches the maximum number of cores, it will begin to scale up by adding new containers.
                </p>

              </ng-container>

              <ng-container *ngIf="scalingDialogData.type === scalingTypes.Ram">

                <h3 class="__scaling-dialog-card_title">Meilisearch vertical RAM scaling</h3>

                <p class="u-desc __scaling-dialog-card_desc">
                  Zerops automatically adjusts the amount of RAM within a user-defined range based on a modifiable threshold of free resources. If your application's RAM usage is consistent and predictable, setting a specific upper limit on RAM can help manage resource allocation efficiently. When RAM scaling is active and the system approaches the maximum allocated RAM, Zerops focuses on optimizing resource usage within the existing configuration, without adding new containers.
                </p>

              </ng-container>

              <ng-container *ngIf="scalingDialogData.type === scalingTypes.Disc">

                <h3 class="__scaling-dialog-card_title">Meilisearch vertical Disk scaling</h3>

                <p class="u-desc __scaling-dialog-card_desc">
                  The disk space is automatically scaled up or down as the data on the disk increases or decreases.
                </p>

              </ng-container>

            </ng-container>

            <ng-container *ngIf="serviceStackType === serviceStackTypes.Typesense">

              <ng-container *ngIf="scalingDialogData.type === scalingTypes.Cpu">

                <h3 class="__scaling-dialog-card_title">Typesense vertical cores scaling</h3>

                <p class="u-desc __scaling-dialog-card_desc">
                  Zerops automatically adjusts the number of cores based on a modifiable threshold of free resources. Unless your application can handle or utilize more than one CPU core, you should limit the scaling range to a single core. When container scaling is active and Zerops reaches the maximum number of cores, it will begin to scale up by adding new containers.
                </p>

              </ng-container>

              <ng-container *ngIf="scalingDialogData.type === scalingTypes.Ram">

                <h3 class="__scaling-dialog-card_title">Typesense vertical RAM scaling</h3>

                <p class="u-desc __scaling-dialog-card_desc">
                  Zerops automatically adjusts the amount of RAM within a user-defined range based on a modifiable threshold of free resources. If your application's RAM usage is consistent and predictable, setting a specific upper limit on RAM can help manage resource allocation efficiently. When RAM scaling is active and the system approaches the maximum allocated RAM, Zerops focuses on optimizing resource usage within the existing configuration, without adding new containers.
                </p>

              </ng-container>

              <ng-container *ngIf="scalingDialogData.type === scalingTypes.Disc">

                <h3 class="__scaling-dialog-card_title">Typesense vertical Disk scaling</h3>

                <p class="u-desc __scaling-dialog-card_desc">
                  The disk space is automatically scaled up or down as the data on the disk increases or decreases.
                </p>

              </ng-container>

            </ng-container>

            <ng-container *ngIf="serviceStackType === serviceStackTypes.Redis">

              <ng-container *ngIf="scalingDialogData.type === scalingTypes.Cpu">

                <h3 class="__scaling-dialog-card_title">KeyDB vertical cores scaling</h3>

                <p class="u-desc __scaling-dialog-card_desc">
                  Zerops automatically adjusts the number of cores based on a modifiable threshold of free resources. Unless your application can handle or utilize more than one CPU core, you should limit the scaling range to a single core. When container scaling is active and Zerops reaches the maximum number of cores, it will begin to scale up by adding new containers.
                </p>

              </ng-container>

              <ng-container *ngIf="scalingDialogData.type === scalingTypes.Ram">

                <h3 class="__scaling-dialog-card_title">KeyDB vertical RAM scaling</h3>

                <p class="u-desc __scaling-dialog-card_desc">
                  Zerops automatically adjusts the amount of RAM within a user-defined range based on a modifiable threshold of free resources. If your application's RAM usage is consistent and predictable, setting a specific upper limit on RAM can help manage resource allocation efficiently. When RAM scaling is active and the system approaches the maximum allocated RAM, Zerops focuses on optimizing resource usage within the existing configuration, without adding new containers.
                </p>

              </ng-container>

              <ng-container *ngIf="scalingDialogData.type === scalingTypes.Disc">

                <h3 class="__scaling-dialog-card_title">KeyDB vertical Disk scaling</h3>

                <p class="u-desc __scaling-dialog-card_desc">
                  The disk space is automatically scaled up or down as the database replicated data increases or decreases.
                  You should not use the local container disk to store non-database data as they are not mirrored across the cluster nor backup-ed.
                </p>

              </ng-container>

            </ng-container>

            <ng-container *ngIf="serviceStackType === serviceStackTypes.Postgres">

              <ng-container *ngIf="scalingDialogData.type === scalingTypes.Cpu">

                <h3 class="__scaling-dialog-card_title">PostgreSQL vertical cores scaling</h3>

                <p class="u-desc __scaling-dialog-card_desc">
                  Zerops automatically adjusts the number of cores based on a modifiable threshold of free resources. Unless your application can handle or utilize more than one CPU core, you should limit the scaling range to a single core. When container scaling is active and Zerops reaches the maximum number of cores, it will begin to scale up by adding new containers.
                </p>

              </ng-container>

              <ng-container *ngIf="scalingDialogData.type === scalingTypes.Ram">

                <h3 class="__scaling-dialog-card_title">PostgreSQL vertical RAM scaling</h3>

                <p class="u-desc __scaling-dialog-card_desc">
                  Zerops automatically adjusts the amount of RAM within a user-defined range based on a modifiable threshold of free resources. If your application's RAM usage is consistent and predictable, setting a specific upper limit on RAM can help manage resource allocation efficiently. When RAM scaling is active and the system approaches the maximum allocated RAM, Zerops focuses on optimizing resource usage within the existing configuration, without adding new containers.
                </p>

              </ng-container>

              <ng-container *ngIf="scalingDialogData.type === scalingTypes.Disc">

                <h3 class="__scaling-dialog-card_title">PostgreSQL vertical Disk scaling</h3>

                <p class="u-desc __scaling-dialog-card_desc">
                  The disk space is automatically scaled up or down as the database replicated data increases or decreases.
                  You should not use the local container disk to store non-database data as they are not mirrored across the cluster nor backup-ed.
                </p>

              </ng-container>

            </ng-container>

            <ng-container *ngIf="serviceStackType === serviceStackTypes.Valkey">

              <ng-container *ngIf="scalingDialogData.type === scalingTypes.Cpu">

                <h3 class="__scaling-dialog-card_title">Valkey vertical cores scaling</h3>

                <p class="u-desc __scaling-dialog-card_desc">
                  Zerops automatically adjusts the number of cores based on a modifiable threshold of free resources. Unless your application can handle or utilize more than one CPU core, you should limit the scaling range to a single core. When container scaling is active and Zerops reaches the maximum number of cores, it will begin to scale up by adding new containers.
                </p>

              </ng-container>

              <ng-container *ngIf="scalingDialogData.type === scalingTypes.Ram">

                <h3 class="__scaling-dialog-card_title">Valkey vertical RAM scaling</h3>

                <p class="u-desc __scaling-dialog-card_desc">
                  Zerops automatically adjusts the amount of RAM within a user-defined range based on a modifiable threshold of free resources. If your application's RAM usage is consistent and predictable, setting a specific upper limit on RAM can help manage resource allocation efficiently. When RAM scaling is active and the system approaches the maximum allocated RAM, Zerops focuses on optimizing resource usage within the existing configuration, without adding new containers.
                </p>

              </ng-container>

              <ng-container *ngIf="scalingDialogData.type === scalingTypes.Disc">

                <h3 class="__scaling-dialog-card_title">Valkey vertical Disk scaling</h3>

                <p class="u-desc __scaling-dialog-card_desc">
                  The disk space is automatically scaled up or down as the database replicated data increases or decreases.
                  You should not use the local container disk to store non-database data as they are not mirrored across the cluster nor backup-ed.
                </p>

              </ng-container>

            </ng-container>

            <ng-container *ngIf="serviceStackType === serviceStackTypes.Qdrant">

              <ng-container *ngIf="scalingDialogData.type === scalingTypes.Cpu">

                <h3 class="__scaling-dialog-card_title">Qdrant vertical cores scaling</h3>

                <p class="u-desc __scaling-dialog-card_desc">
                  Zerops automatically adjusts the number of cores based on a modifiable threshold of free resources. Unless your application can handle or utilize more than one CPU core, you should limit the scaling range to a single core. When container scaling is active and Zerops reaches the maximum number of cores, it will begin to scale up by adding new containers.
                </p>

              </ng-container>

              <ng-container *ngIf="scalingDialogData.type === scalingTypes.Ram">

                <h3 class="__scaling-dialog-card_title">Qdrant vertical RAM scaling</h3>

                <p class="u-desc __scaling-dialog-card_desc">
                  Zerops automatically adjusts the amount of RAM within a user-defined range based on a modifiable threshold of free resources. If your application's RAM usage is consistent and predictable, setting a specific upper limit on RAM can help manage resource allocation efficiently. When RAM scaling is active and the system approaches the maximum allocated RAM, Zerops focuses on optimizing resource usage within the existing configuration, without adding new containers.
                </p>

              </ng-container>

              <ng-container *ngIf="scalingDialogData.type === scalingTypes.Disc">

                <h3 class="__scaling-dialog-card_title">Qdrant vertical Disk scaling</h3>

                <p class="u-desc __scaling-dialog-card_desc">
                  The disk space is automatically scaled up or down as the database replicated data increases or decreases.
                  You should not use the local container disk to store non-database data as they are not mirrored across the cluster nor backup-ed.
                </p>

              </ng-container>

            </ng-container>

            <ng-container *ngIf="serviceStackType === serviceStackTypes.Nats">

              <ng-container *ngIf="scalingDialogData.type === scalingTypes.Cpu">

                <h3 class="__scaling-dialog-card_title">NATS vertical cores scaling</h3>

                <p class="u-desc __scaling-dialog-card_desc">
                  Zerops automatically adjusts the number of cores based on a modifiable threshold of free resources. Unless your application can handle or utilize more than one CPU core, you should limit the scaling range to a single core. When container scaling is active and Zerops reaches the maximum number of cores, it will begin to scale up by adding new containers.
                </p>

              </ng-container>

              <ng-container *ngIf="scalingDialogData.type === scalingTypes.Ram">

                <h3 class="__scaling-dialog-card_title">NATS vertical RAM scaling</h3>

                <p class="u-desc __scaling-dialog-card_desc">
                  Zerops automatically adjusts the amount of RAM within a user-defined range based on a modifiable threshold of free resources. If your application's RAM usage is consistent and predictable, setting a specific upper limit on RAM can help manage resource allocation efficiently. When RAM scaling is active and the system approaches the maximum allocated RAM, Zerops focuses on optimizing resource usage within the existing configuration, without adding new containers.
                </p>

              </ng-container>

              <ng-container *ngIf="scalingDialogData.type === scalingTypes.Disc">

                <h3 class="__scaling-dialog-card_title">NATS vertical Disk scaling</h3>

                <p class="u-desc __scaling-dialog-card_desc">
                  The disk space is automatically scaled up or down as the database replicated data increases or decreases.
                  You should not use the local container disk to store non-database data as they are not mirrored across the cluster nor backup-ed.
                </p>

              </ng-container>

            </ng-container>

            <ng-container *ngIf="serviceStackType === serviceStackTypes.Kafka">

              <ng-container *ngIf="scalingDialogData.type === scalingTypes.Cpu">

                <h3 class="__scaling-dialog-card_title">Apache Kafka vertical cores scaling</h3>

                <p class="u-desc __scaling-dialog-card_desc">
                  Zerops automatically adjusts the number of cores based on a modifiable threshold of free resources. Unless your application can handle or utilize more than one CPU core, you should limit the scaling range to a single core. When container scaling is active and Zerops reaches the maximum number of cores, it will begin to scale up by adding new containers.
                </p>

              </ng-container>

              <ng-container *ngIf="scalingDialogData.type === scalingTypes.Ram">

                <h3 class="__scaling-dialog-card_title">Apache Kafka vertical RAM scaling</h3>

                <p class="u-desc __scaling-dialog-card_desc">
                  Zerops automatically adjusts the amount of RAM within a user-defined range based on a modifiable threshold of free resources. If your application's RAM usage is consistent and predictable, setting a specific upper limit on RAM can help manage resource allocation efficiently. When RAM scaling is active and the system approaches the maximum allocated RAM, Zerops focuses on optimizing resource usage within the existing configuration, without adding new containers.
                </p>

              </ng-container>

              <ng-container *ngIf="scalingDialogData.type === scalingTypes.Disc">

                <h3 class="__scaling-dialog-card_title">Apache Kafka vertical Disk scaling</h3>

                <p class="u-desc __scaling-dialog-card_desc">
                  The disk space is automatically scaled up or down as the database replicated data increases or decreases.
                  You should not use the local container disk to store non-database data as they are not mirrored across the cluster nor backup-ed.
                </p>

              </ng-container>

            </ng-container>

            <ng-container *ngIf="serviceStackType === serviceStackTypes.RabbitMq">

              <ng-container *ngIf="scalingDialogData.type === scalingTypes.Cpu">

                <h3 class="__scaling-dialog-card_title">RabbitMQ vertical cores scaling</h3>

                <p class="u-desc __scaling-dialog-card_desc">
                  Zerops automatically adjusts the number of cores based on a modifiable threshold of free resources. Unless your application can handle or utilize more than one CPU core, you should limit the scaling range to a single core. When container scaling is active and Zerops reaches the maximum number of cores, it will begin to scale up by adding new containers.
                </p>

              </ng-container>

              <ng-container *ngIf="scalingDialogData.type === scalingTypes.Ram">

                <h3 class="__scaling-dialog-card_title">RabbitMQ vertical RAM scaling</h3>

                <p class="u-desc __scaling-dialog-card_desc">
                  Zerops automatically adjusts the amount of RAM within a user-defined range based on a modifiable threshold of free resources. If your application's RAM usage is consistent and predictable, setting a specific upper limit on RAM can help manage resource allocation efficiently. When RAM scaling is active and the system approaches the maximum allocated RAM, Zerops focuses on optimizing resource usage within the existing configuration, without adding new containers.
                </p>

              </ng-container>

              <ng-container *ngIf="scalingDialogData.type === scalingTypes.Disc">

                <h3 class="__scaling-dialog-card_title">RabbitMQ vertical Disk scaling</h3>

                <p class="u-desc __scaling-dialog-card_desc">
                  The disk space is automatically scaled up or down as the data on the disk increases or decreases.
                </p>

              </ng-container>

            </ng-container>

            <ng-container *ngIf="serviceStackType === serviceStackTypes.MariaDb">

              <ng-container *ngIf="scalingDialogData.type === scalingTypes.Cpu">

                <h3 class="__scaling-dialog-card_title">Mariadb vertical cores scaling</h3>

                <p class="u-desc __scaling-dialog-card_desc">
                  Zerops automatically adjusts the number of cores based on a modifiable threshold of free resources. Unless your application can handle or utilize more than one CPU core, you should limit the scaling range to a single core. When container scaling is active and Zerops reaches the maximum number of cores, it will begin to scale up by adding new containers.
                </p>

              </ng-container>

              <ng-container *ngIf="scalingDialogData.type === scalingTypes.Ram">

                <h3 class="__scaling-dialog-card_title">Mariadb vertical RAM scaling</h3>

                <p class="u-desc __scaling-dialog-card_desc">
                  Zerops automatically adjusts the amount of RAM within a user-defined range based on a modifiable threshold of free resources. If your application's RAM usage is consistent and predictable, setting a specific upper limit on RAM can help manage resource allocation efficiently. When RAM scaling is active and the system approaches the maximum allocated RAM, Zerops focuses on optimizing resource usage within the existing configuration, without adding new containers.
                </p>

              </ng-container>

              <ng-container *ngIf="scalingDialogData.type === scalingTypes.Disc">

                <h3 class="__scaling-dialog-card_title">Mariadb vertical Disk scaling</h3>

                <p class="u-desc __scaling-dialog-card_desc">
                  The disk space is automatically scaled up or down as the database replicated data increases or decreases.
                  You should not use the local container disk to store non-database data as they are not mirrored across the cluster nor backup-ed.
                </p>

              </ng-container>

            </ng-container>

            <ng-container *ngIf="serviceStackType === serviceStackTypes.MongoDb">

              <ng-container *ngIf="scalingDialogData.type === scalingTypes.Cpu">

                <h3 class="__scaling-dialog-card_title">MongoDB vertical cores scaling</h3>

                <p class="u-desc __scaling-dialog-card_desc">
                  Zerops automatically adjusts the number of cores based on a modifiable threshold of free resources. Unless your application can handle or utilize more than one CPU core, you should limit the scaling range to a single core. When container scaling is active and Zerops reaches the maximum number of cores, it will begin to scale up by adding new containers.
                </p>

              </ng-container>

              <ng-container *ngIf="scalingDialogData.type === scalingTypes.Ram">

                <h3 class="__scaling-dialog-card_title">MongoDB vertical RAM scaling</h3>

                <p class="u-desc __scaling-dialog-card_desc">
                  Zerops automatically adjusts the amount of RAM within a user-defined range based on a modifiable threshold of free resources. If your application's RAM usage is consistent and predictable, setting a specific upper limit on RAM can help manage resource allocation efficiently. When RAM scaling is active and the system approaches the maximum allocated RAM, Zerops focuses on optimizing resource usage within the existing configuration, without adding new containers.
                </p>

              </ng-container>

              <ng-container *ngIf="scalingDialogData.type === scalingTypes.Disc">

                <h3 class="__scaling-dialog-card_title">MongoDB vertical Disk scaling</h3>

                <p class="u-desc __scaling-dialog-card_desc">
                  Disk space can be scaled up and down without restarting the process, you however should not use Disk
                  to store your files as the containers are volatile and their data not synchronized. Use one of the storage services
                  to store your files.
                </p>

              </ng-container>

            </ng-container>

            <ng-container *ngIf="serviceStackType === serviceStackTypes.SharedStorage">

              <ng-container *ngIf="scalingDialogData.type === scalingTypes.Cpu">

                <h3 class="__scaling-dialog-card_title">Shared storage vertical cores scaling</h3>

                <p class="u-desc __scaling-dialog-card_desc">
                  Zerops automatically adjusts the number of cores based on a modifiable threshold of free resources. Unless your application can handle or utilize more than one CPU core, you should limit the scaling range to a single core. When container scaling is active and Zerops reaches the maximum number of cores, it will begin to scale up by adding new containers.
                </p>

              </ng-container>

              <ng-container *ngIf="scalingDialogData.type === scalingTypes.Ram">

                <h3 class="__scaling-dialog-card_title">Shared storage vertical RAM scaling</h3>

                <p class="u-desc __scaling-dialog-card_desc">
                  Zerops automatically adjusts the amount of RAM within a user-defined range based on a modifiable threshold of free resources. If your application's RAM usage is consistent and predictable, setting a specific upper limit on RAM can help manage resource allocation efficiently. When RAM scaling is active and the system approaches the maximum allocated RAM, Zerops focuses on optimizing resource usage within the existing configuration, without adding new containers.
                </p>

              </ng-container>

              <ng-container *ngIf="scalingDialogData.type === scalingTypes.Disc">

                <h3 class="__scaling-dialog-card_title">Shared storage vertical Disk scaling</h3>

                <p class="u-desc __scaling-dialog-card_desc">
                  The disk space is automatically scaled up or down as the data on the disk increases or decreases.
                </p>

              </ng-container>

            </ng-container>

          </div>

          <!-- settings -->
          <div class="__scaling-dialog-card_setting">

            <!-- scaling range Containers -->
            @if (scalingDialogData.type === scalingTypes.Container) {
              @if (defaultMinMax) {
                <div class="fx:pb-1">
                  <nouislider
                    class="__slider"
                    [(ngModel)]="scalingModel"
                    [connect]="true"
                    [tooltips]="true"
                    [format]="containerFormatter"
                    [step]="scalingButtonvalues?.[scalingDialogData.type]?.buttons?.[0]"
                    [min]="defaultMinMax.defaultMin"
                    [max]="defaultMinMax.defaultMax"
                  />
                </div>
              }
            }

            <!-- scaling range CPU -->
            @if (scalingDialogData.type === scalingTypes.Cpu) {
              @if (defaultMinMax) {
                @if (singleMode) {
                  <nouislider
                    class="__slider"
                    [(ngModel)]="scalingModelSingle"
                    [connect]="false"
                    [format]="cpuFormatter"
                    [tooltips]="true"
                    [step]="scalingButtonvalues?.[scalingDialogData.type]?.buttons?.[0]"
                    [min]="defaultMinMax.defaultMin"
                    [max]="defaultMinMax.defaultMax"
                  />
                } @else {
                  <nouislider
                    class="__slider"
                    [(ngModel)]="scalingModel"
                    [connect]="true"
                    [format]="cpuFormatter"
                    [tooltips]="true"
                    [step]="scalingButtonvalues?.[scalingDialogData.type]?.buttons?.[0]"
                    [min]="defaultMinMax.defaultMin"
                    [max]="defaultMinMax.defaultMax"
                  />
                }
              }
            }

            <!-- scaling range RAM -->
            @if (scalingDialogData.type === scalingTypes.Ram) {
              @if (defaultMinMax) {
                @if (singleMode) {
                  <nouislider
                    class="__slider"
                    [(ngModel)]="scalingModelSingle"
                    [connect]="false"
                    [format]="ramFormatter"
                    [tooltips]="true"
                    [step]="scalingButtonvalues?.[scalingDialogData.type]?.buttons?.[0]"
                    [min]="defaultMinMax.defaultMin"
                    [max]="defaultMinMax.defaultMax"
                  />
                } @else {
                  <nouislider
                    class="__slider"
                    [(ngModel)]="scalingModel"
                    [connect]="true"
                    [format]="ramFormatter"
                    [tooltips]="true"
                    [step]="scalingButtonvalues?.[scalingDialogData.type]?.buttons?.[0]"
                    [min]="defaultMinMax.defaultMin"
                    [max]="defaultMinMax.defaultMax"
                  />
                }


              }
            }

            <!-- scaling range DISK -->
            @if (scalingDialogData.type === scalingTypes.Disc) {
              @if (singleMode) {
                <div>
                  <zef-form-field>
                    <mat-form-field color="accent">
                      <mat-label>
                        Set disk to
                        (<span *ngIf="defaultMinMax.defaultMin">min.
                          <strong>
                            {{ defaultMinMax.defaultMin }}
                            <span *ngIf="scalingTypeSuffixMap[scalingDialogData.type]">{{ scalingTypeSuffixMap[scalingDialogData.type] }}</span>
                          </strong>
                        </span>)
                      </mat-label>

                      <input
                        matInput
                        type="number"
                        [max]="defaultMinMax.defaultMax ? defaultMinMax.defaultMax : undefined"
                        [(ngModel)]="scalingDialogData.max"
                        autocomplete="off"
                      />
                      <span class="__input-suffix" matSuffix *ngIf="scalingTypeSuffixMap[scalingDialogData.type]">{{ scalingTypeSuffixMap[scalingDialogData.type] }}</span>
                    </mat-form-field>
                  </zef-form-field>

                  <div class="__controls">

                    <div>
                      <mat-button-toggle-group>
                        <mat-button-toggle
                          *ngFor="let buttonValue of scalingButtonvalues[scalingDialogData.type]?.buttons; let index = index;"
                          [checked]="index === 0"
                          (click)="maxToggleButtonValue = buttonValue"
                          [value]="buttonValue">{{ buttonValue }} <span class="__control-button-suffix">{{ scalingTypeSuffixMap[scalingDialogData.type] }}</span>
                        </mat-button-toggle>
                      </mat-button-toggle-group>
                    </div>

                    <div>

                      <button
                        mat-icon-button
                        class="__control-button"
                        [disabled]="scalingDialogData.max - maxToggleButtonValue < defaultMinMax.defaultMin"
                        (click)="scalingDialogData.max = scalingDialogData.max - maxToggleButtonValue">
                        <mat-icon class="__control-button-icon">remove</mat-icon>
                      </button>

                      <button
                        mat-icon-button
                        class="__control-button"
                        [disabled]="scalingDialogData.max + maxToggleButtonValue > defaultMinMax.defaultMax"
                        (click)="scalingDialogData.max = scalingDialogData.max + maxToggleButtonValue">
                        <mat-icon class="__control-button-icon">add</mat-icon>
                      </button>

                    </div>

                  </div>

                </div>
              } @else {
                <div class="__scaling-dialog-card_grid">
                  <!-- min -->
                  <div>

                    <zef-form-field>
                      <mat-form-field color="accent">
                        <mat-label>
                          Start with
                          (<span *ngIf="defaultMinMax.defaultMin">min.
                            <strong>
                              {{ defaultMinMax.defaultMin }}
                              <span *ngIf="scalingTypeSuffixMap[scalingDialogData.type]">{{ scalingTypeSuffixMap[scalingDialogData.type] }}</span>
                            </strong>
                          </span>)
                        </mat-label>

                        <input
                          matInput
                          type="number"
                          [min]="defaultMinMax.defaultMin ? defaultMinMax.defaultMin : undefined"
                          [max]="defaultMinMax.defaultMax ? defaultMinMax.defaultMax : undefined"
                          [(ngModel)]="scalingDialogData.min"
                          autocomplete="off"
                        />
                        <span class="__input-suffix" matSuffix *ngIf="scalingTypeSuffixMap[scalingDialogData.type]">{{ scalingTypeSuffixMap[scalingDialogData.type] }}</span>
                      </mat-form-field>
                    </zef-form-field>

                    <div class="__controls">

                      <div>
                        <mat-button-toggle-group>
                          <mat-button-toggle
                            *ngFor="let buttonValue of scalingButtonvalues[scalingDialogData.type]?.buttons; let index = index;"
                            [checked]="index === 0"
                            (click)="minToggleButtonValue = buttonValue"
                            [value]="buttonValue">{{ buttonValue }} <span class="__control-button-suffix">{{ scalingTypeSuffixMap[scalingDialogData.type] }}</span>
                          </mat-button-toggle>
                        </mat-button-toggle-group>
                      </div>

                      <div>

                        <button
                          mat-icon-button
                          class="__control-button"
                          [disabled]="scalingDialogData.min - minToggleButtonValue < defaultMinMax.defaultMin"
                          (click)="scalingDialogData.min = scalingDialogData.min - minToggleButtonValue">
                          <mat-icon class="__control-button-icon">remove</mat-icon>
                        </button>

                        <button
                          mat-icon-button
                          class="__control-button"
                          [disabled]="scalingDialogData.min + minToggleButtonValue > defaultMinMax.defaultMax"
                          (click)="scalingDialogData.min = scalingDialogData.min + minToggleButtonValue">
                          <mat-icon class="__control-button-icon">add</mat-icon>
                        </button>

                      </div>

                    </div>

                  </div>

                  <!-- max -->
                  <div>
                    <zef-form-field>
                      <mat-form-field color="accent">
                        <mat-label>
                          Scale up to

                          (<span *ngIf="defaultMinMax.defaultMax">max.
                            <strong>
                              {{ defaultMinMax.defaultMax }}
                              <span *ngIf="scalingTypeSuffixMap[scalingDialogData.type]">{{ scalingTypeSuffixMap[scalingDialogData.type] }}</span>
                            </strong>
                          </span>)

                        </mat-label>
                        <input
                          matInput
                          type="number"
                          [min]="defaultMinMax.defaultMin ? defaultMinMax.defaultMin : undefined"
                          [max]="defaultMinMax.defaultMax ? defaultMinMax.defaultMax : undefined"
                          [(ngModel)]="scalingDialogData.max"
                          autocomplete="off"
                        />
                        <span class="__input-suffix" matSuffix *ngIf="scalingTypeSuffixMap[scalingDialogData.type]">{{ scalingTypeSuffixMap[scalingDialogData.type] }}</span>
                      </mat-form-field>
                    </zef-form-field>

                    <div class="__controls">

                      <div>
                        <mat-button-toggle-group>
                          <mat-button-toggle
                            *ngFor="let buttonValue of scalingButtonvalues[scalingDialogData.type]?.buttons; let index = index;"
                            [checked]="index === 0"
                            (click)="maxToggleButtonValue = buttonValue"
                            [value]="buttonValue">{{ buttonValue }} <span class="__control-button-suffix">{{ scalingTypeSuffixMap[scalingDialogData.type] }}</span>
                          </mat-button-toggle>
                        </mat-button-toggle-group>
                      </div>

                      <div>

                        <button
                          mat-icon-button
                          class="__control-button"
                          [disabled]="scalingDialogData.max - maxToggleButtonValue < defaultMinMax.defaultMin"
                          (click)="scalingDialogData.max = scalingDialogData.max - maxToggleButtonValue">
                          <mat-icon class="__control-button-icon">remove</mat-icon>
                        </button>

                        <button
                          mat-icon-button
                          class="__control-button"
                          [disabled]="scalingDialogData.max + maxToggleButtonValue > defaultMinMax.defaultMax"
                          (click)="scalingDialogData.max = scalingDialogData.max + maxToggleButtonValue">
                          <mat-icon class="__control-button-icon">add</mat-icon>
                        </button>

                      </div>

                    </div>

                  </div>
                </div>
              }
            }

            <!-- advanced settings -->
            @if ((scalingDialogData.type === scalingTypes.Cpu || scalingDialogData.type === scalingTypes.Ram) && !singleMode) {

              <zui-separator [orientation]="'horizontal'" />

              <div class="__advanced-settings">

                <div class="fx:text-center">
                  <mat-slide-toggle [(ngModel)]="showAdvancedSettings">
                    <strong>Show advanced scaling settings</strong>
                  </mat-slide-toggle>
                </div>

                @if (showAdvancedSettings) {

                  <zui-separator [size]="'small'" [spacing]="'small'" />

                  <!-- advanced scaling CPU -->
                  @if (scalingDialogData.type === scalingTypes.Cpu) {

                    @if (value.verticalAutoscaling?.cpuMode === 'DEDICATED' || (!value.verticalAutoscaling?.cpuMode && defaultVerticalAutoscaling.cpuMode === 'DEDICATED')) {
                      <!-- CPU min frees -->
                      <div>

                        <div class="fx:text-center  fx:pb-1">
                          <h4 class="fx:pb-0-5">CPU Scaling Thresholds</h4>
                          <p class="u-desc  __scaling-dialog-card_desc  __scaling-dialog-card_desc--cpu">
                            Cores scale up based on the higher threshold of single or total core utilization,
                            ensuring optimal efficiency and resource management.
                          </p>
                        </div>

                        <!-- CPU min free -->
                        <div class="fx:grid  fx:grid-cols-1  fx:gap-bu1-5  fx:pt-0-5  __min-free-fields">

                          <!-- CPU min free count -->
                          <div>
                            <zef-form-field class="__min-free-form-field">
                              <mat-form-field color="accent" [floatLabel]="'always'">

                                <mat-label>Min. Free CPU Cores (%)</mat-label>

                                <input
                                  matInput
                                  type="number"
                                  [placeholder]="((defaultVerticalAutoscaling?.minFreeResource?.cpuCoreCount * 100) | floor) + ' (default)'"
                                  [min]="0"
                                  [max]="(defaultMinMax.defaultMax * 100) / 1.5"
                                  [(ngModel)]="scalingDialogData.minFree"
                                  autocomplete="off"
                                />

                                <span
                                  class="__input-suffix"
                                  matSuffix>
                                  %
                                </span>

                                <mat-hint class="__hint-text">
                                  Initiate scale-up when the absolute free capacity drops below a fixed fraction of a single CPU core's capacity, irrespective of the total number of cores. This threshold is set as a fixed percentage of a single core's capacity to maintain a consistent buffer. For example, setting this threshold at 20% (or 0.2 of a core) means that with one core, at least 20% of that core should remain free, and with two cores, at least 10% per core should remain free, thus always reserving the equivalent of 0.2 of a core as free.
                                </mat-hint>

                              </mat-form-field>
                            </zef-form-field>
                          </div>

                          <!-- CPU min free percent -->
                          <div>
                            <zef-form-field class="__min-free-form-field" last>
                              <mat-form-field color="accent" [floatLabel]="'always'">

                                <mat-label>Dynamic Min. Free Total Core Percent</mat-label>

                                <input
                                  matInput
                                  type="number"
                                  [min]="0"
                                  [max]="99"
                                  [placeholder]="defaultVerticalAutoscaling?.minFreeResource?.cpuCorePercent + ' (default)'"
                                  [(ngModel)]="scalingDialogData.minFreePct"
                                  autocomplete="off"
                                />
                                <span
                                  class="__input-suffix"
                                  matSuffix>
                                  %
                                </span>

                                <mat-hint class="__hint-text">
                                  Trigger scale-up when the total free capacity across all assigned CPU cores falls below a specified percentage of the total allocated CPU capacity. This percentage should be set based on the desired free capacity relative to the total number of cores in use. For example, setting this to 20% means that the system will scale up when the total free capacity is less than 20% of the combined capacity of all cores. This setting dynamically adjusts as the number of cores changes, ideal for accommodating varying load distributions.
                                </mat-hint>

                              </mat-form-field>
                            </zef-form-field>

                          </div>

                        </div>

                        <zui-separator [size]="'small'" [spacing]="'small'" />

                      </div>
                    }

                    <!-- start core count -->
                     @if (!singleMode) {
                      <div class="fx:text-center  __start-core-count  fx:pt-0-5">
                        <h4 class="fx:pb-0-5">Container Start Core Count</h4>
                        <p class="u-desc __scaling-dialog-card_desc">
                          How many cores should containers be allocated <br/>when they start to ensure a reliable and fast start
                        </p>

                        <div>
                          <zef-form-field last class="__advanced-short-field">
                            <mat-form-field color="accent" [floatLabel]="'always'">

                              <mat-label>Start with</mat-label>

                              <input
                                matInput
                                type="number"
                                [min]="0"
                                [placeholder]="defaultVerticalAutoscaling?.startCpuCoreCount + ' (default)'"
                                [max]="defaultMinMax.defaultMax"
                                [(ngModel)]="scalingDialogData.startCount"
                                autocomplete="off"
                              />

                              <span
                                class="__input-suffix"
                                matSuffix>
                                cores
                              </span>

                            </mat-form-field>
                          </zef-form-field>
                        </div>

                      </div>
                    }

                  }

                  <!-- advanced scaling RAM -->
                  @if (scalingDialogData.type === scalingTypes.Ram) {
                    <div class="fx:text-center  fx:pb-1">
                      <h4 class="fx:pb-0-5">RAM Scaling Thresholds</h4>
                      <p class="u-desc  __scaling-dialog-card_desc  __scaling-dialog-card_desc--ram">
                        Memory scales based on the higher of two thresholds: a fixed minimum (in GB) or a percentage of granted RAM. This ensures efficient resource management, with adjustments made according to current memory demands.
                      </p>
                    </div>

                    <!-- RAM min free -->
                    <div class="fx:grid  fx:grid-cols-1  fx:gap-bu1-5  fx:pt-0-5  __min-free-fields">

                      <!-- RAM min free count -->
                      <div>
                        <zef-form-field class="__min-free-form-field">
                          <mat-form-field color="accent" [floatLabel]="'always'">

                            <mat-label>Minimum Free RAM (GB)</mat-label>

                            <input
                              matInput
                              type="number"
                              [min]="defaultVerticalAutoscaling?.minFreeResource?.memoryGBytes"
                              [placeholder]="defaultVerticalAutoscaling?.minFreeResource?.memoryGBytes + ' (default)'"
                              [max]="(scalingDialogData.max - 0.125)"
                              [(ngModel)]="scalingDialogData.minFree"
                              autocomplete="off"
                            />

                            <span
                              class="__input-suffix"
                              matSuffix>
                              GB
                            </span>

                            <mat-hint class="__hint-text">
                              This setting specifies an absolute threshold for free RAM, ensuring that a minimum buffer of free memory is always available. For example, setting this to 200 MB means the system will trigger additional memory resources when available RAM falls below this fixed amount. This setting provides a consistent baseline of free RAM, ideal for maintaining system stability and responsiveness, especially in scenarios with low to moderate memory usage.
                            </mat-hint>

                          </mat-form-field>
                        </zef-form-field>
                      </div>

                      <!-- RAM min free percent -->
                      <div>
                        <zef-form-field class="__min-free-form-field" last>
                          <mat-form-field color="accent" [floatLabel]="'always'">

                            <mat-label>Minimum Free RAM (% of Granted)</mat-label>

                            <input
                              matInput
                              type="number"
                              [min]="0"
                              [max]="99"
                              [placeholder]="defaultVerticalAutoscaling?.minFreeResource?.memoryPercent + ' (default)'"
                              [(ngModel)]="scalingDialogData.minFreePct"
                              autocomplete="off"
                            />
                            <span
                              class="__input-suffix"
                              matSuffix>
                              %
                            </span>

                            <mat-hint class="__hint-text">
                              This setting establishes a dynamic threshold for free RAM based on a percentage of the total granted memory. For instance, setting this to 5% adapts the free memory threshold according to the overall memory allocation. This percentage-based approach ensures that as the total memory usage increases, the buffer also scales up proportionally. For example, with a total RAM of 12 GB, a setting of 5% would reserve a minimum of 600 MB as free. This dynamic adjustment is particularly useful for handling varying loads, ensuring there is always a proportionate amount of free RAM available relative to the total usage.
                            </mat-hint>

                          </mat-form-field>
                        </zef-form-field>
                      </div>

                    </div>
                  }

                }

              </div>
            }

          </div>

          <!-- apply -->
          <div class="__scaling-dialog-card_button-wrap">
            <button
              class="c-button  c-button--full"
              mat-flat-button
              type="button"
              [disabled]="defaultMinMax.defaultMin && defaultMinMax.defaultMax
                ? (scalingDialogData.min < defaultMinMax.defaultMin
                  || scalingDialogData.min > defaultMinMax.defaultMax
                  || scalingDialogData.max > defaultMinMax.defaultMax
                  || scalingDialogData.max < defaultMinMax.defaultMin
                  || scalingDialogData.max > defaultMinMax.defaultMax
                  || scalingDialogData.min > scalingDialogData.max)
                : false"
              (click)="applyScalingChanges();"
              color="accent">
              Apply changes
            </button>
          </div>

        </zef-form>
      </mat-card>
    </zef-scrollbar>

    <button
      class="__scaling-dialog-close-button"
      type="button"
      (click)="closeScalingDialog()"
      mat-icon-button>
      <mat-icon>close</mat-icon>
    </button>

  </div>
</zef-dialog>
