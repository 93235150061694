import {
  Component,
  ChangeDetectionStrategy,
  viewChild,
  effect,
  inject,
  DestroyRef,
  input,
  OnDestroy,
  computed,
  output,
  signal
} from '@angular/core';
import { HashMap, ZefReactiveComponent } from '@zerops/zef/core';
import { NgTerminalComponent } from 'ng-terminal';
import { Terminal } from '@xterm/xterm';
import {
  Subject,
  Observable,
  timer,
  of,
  merge,
  combineLatest,
  switchMap,
  catchError,
  retry,
  filter,
  distinctUntilChanged,
  map,
  withLatestFrom,
  tap
} from 'rxjs';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { WebSocketSubject } from 'rxjs/webSocket';
import { TerminalApi } from './terminal.api';
import { Container } from '@zerops/zerops/core/container-base';
import { WebLinksAddon } from '@xterm/addon-web-links';
import { ClipboardAddon } from '@xterm/addon-clipboard';

enum WebsocketMessageTypes {
  Pong = 1,
  Io = 2,
  Resize = 3
}

@Component({
  selector: 'z-terminal',
  templateUrl: './terminal.feature.html',
  styleUrls: [ './terminal.feature.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TerminalFeature extends ZefReactiveComponent implements OnDestroy {
  // # Deps
  #destroyRef = inject(DestroyRef);
  #api = inject(TerminalApi);

  // # Data
  // -- sync
  terminalInstance: Terminal;
  terminalRef = viewChild(NgTerminalComponent);
  serviceId = input<string>();
  containerId = input<string>();
  containers = input<Container[]>();
  containerMap = computed<HashMap<Container>>(() => {
    if (this.containers()?.length) {
      return this.containers().reduce((obj: HashMap<Container>, itm) => {
        obj[itm.id] = itm;
        return obj;
      }, {});
    }
    return {};
  });
  showFilters = input<boolean>(true);

  // -- angular
  filterChange = output<any>();

  // -- async
  stream$: Observable<any>;
  #serviceId$ = toObservable(this.serviceId);
  #containerId$ = toObservable(this.containerId);
  #wsConnection$: WebSocketSubject<any>;
  #manualReconnectTrigger$ = new Subject<void>();
  connectionStatus = signal(false);
  connectingStatus = signal(true);

  constructor() {
    super();

    effect(() => {
      if (!!this.terminalInstance || !this.terminalRef()) { return; }
      this.terminalInstance = this.terminalRef().underlying;
      this.#init();
    });

    effect(() => {
      if (this.connectionStatus()) {
        setTimeout(() => {
          this.send(JSON.stringify({
            rows: this.terminalInstance.rows,
            cols: this.terminalInstance.cols
          }), WebsocketMessageTypes.Resize);
        });
      }
    })
  }

  focus() {
    this.terminalRef().underlying?.focus();
  }

  send(message: string, type = WebsocketMessageTypes.Io) {
    if (this.#wsConnection$ && !this.#wsConnection$.closed) {
      this.#wsConnection$.next({ type, message });
    } else {
      console.warn('WebSocket is not connected. Message not sent:', message);
    }
  }

  #createConnectionStream() {
    const inputChanges$ = combineLatest([
      this.#serviceId$.pipe(distinctUntilChanged()),
      this.#containerId$.pipe(distinctUntilChanged())
    ]).pipe(
      filter(([ serviceId ]) => !!serviceId),
      map(([ serviceId, containerId ]) => ({ serviceId, containerId }))
    );

    const reconnectTrigger$ = merge(
      inputChanges$,
      this.#manualReconnectTrigger$
    );

    return reconnectTrigger$.pipe(
      withLatestFrom(inputChanges$),
      tap(() => {
        if (this.#wsConnection$) {
          this.connectionStatus.set(false);
          this.#wsConnection$.complete();
        }
        if (!this.connectingStatus()) {
          this.connectingStatus.set(true);
        }
      }),
      switchMap(([ _, { serviceId, containerId } ]) =>
        this.#api.auth$(serviceId, containerId).pipe(
          switchMap(({ accessToken, host }) => {
            this.#wsConnection$ = this.#api.createWebSocketConnection(accessToken, containerId, host);
            this.connectionStatus.set(true);
            this.terminalInstance.reset();

            return this.#wsConnection$.pipe(
              tap({
                complete: () => {
                  this.connectionStatus.set(false);
                }
              })
            );
          }),
          catchError(() => {
            this.connectionStatus.set(false);
            this.#manualReconnectTrigger$.next()
            return of(undefined);
          }),
          filter((message) => !!message)
        )
      ),
      retry({
        count: 5,
        delay: (_, retryCount) => {
          return timer(5000 * Math.min(retryCount, 10));
        }
      }),
      takeUntilDestroyed(this.#destroyRef)
    );
  }

  #init() {
    const ngTerminal = this.terminalRef();

    ngTerminal.setXtermOptions({
      fontFamily: '"DejaVu Sans Mono", monospace',
      cursorBlink: true,
      fontSize: 13,
      convertEol: true
    });

    this.terminalInstance.loadAddon(new ClipboardAddon());
    this.terminalInstance.loadAddon(new WebLinksAddon());

    this.terminalInstance.onResize(({ rows, cols }) => {
      this.send(JSON.stringify({ rows, cols }), WebsocketMessageTypes.Resize);
    });

    ngTerminal
      .onData()
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe((input) => {
        this.send(input, WebsocketMessageTypes.Io);
      });

    this.#createConnectionStream()
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe((msg) => {
        if (msg?.type === WebsocketMessageTypes.Io) {
          ngTerminal.write(msg.message || '');
        }

        if (msg.type === WebsocketMessageTypes.Pong) {
          this.send('', WebsocketMessageTypes.Pong);
        }
      });
  }

  reconnect() {
    this.#manualReconnectTrigger$.next();
  }
}
