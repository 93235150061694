import { NgModule } from '@angular/core';
import { DebugSwitcherTranslationsModule } from './debug-switcher.translations';
import { DebugSwitcherFeature } from './debug-switcher.feature';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [ DebugSwitcherFeature ],
  imports: [
    CommonModule,
    DebugSwitcherTranslationsModule,
    MatButtonModule
  ],
  exports: [ DebugSwitcherFeature ]
})
export class DebugSwitcherModule {

}
