import { Component, ChangeDetectionStrategy, input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';

@Component({
  selector: 'zui-tip-card',
  templateUrl: './tip-card.component.html',
  styleUrls: [ './tip-card.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatCardModule,
    MatIconModule
  ]
})
export class ZuiTipCardComponent {
  icon = input('tips_and_updates');
  theme = input<'tip' | 'warning'>('tip');
}
