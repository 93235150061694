export class ZefProgressState {
  progress: ZefProgress[];

  constructor() {
    this.progress = [];
  }
}

// TODO: interface
export interface ZefProgressConfig {
}

export interface ZefProgress {
  key: string;
  id?: string | number;
  progress?: number;
  meta?: any;
}

export type ZefProgressMap = Record<string, Record<string, ZefProgress>>
